import React, { Component } from "react";
import AuthService from "../../services/auth.service";
import {
  Avatar,
  Layout,
  Breadcrumb,
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  notification,
  Tabs,
} from "antd";
import { getAvatarColor } from "../../services/colors.service";
import { formatDate } from "../../services/helpers.service";
import UsersService from "../../services/user.service";
import "../../components/css/Profile.css";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import itemRender from "../../utils/itemsRender";
const { Content, Header } = Layout;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      currentUser: null,
      isLoading: false,
      fields: null,
    };
    if (!localStorage.getItem("user")) {
      this.props.history("/login");
    }

    this.loadCurrentUser = this.loadCurrentUser.bind(this);
  }

  loadCurrentUser() {
    this.setState({
      isLoading: true,
    });
    const user = AuthService.getCurrentUser();

    if (user) {
      UsersService.getUserAndCompanyById(user.id)
        .then((data) => {
          this.setState({
            currentUser: AuthService.getCurrentUser(),
            isAdminBoard: user.role,
            isAuthenticated: true,
            isLoading: false,
            fields: data.data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  handleLogout() {
    AuthService.logout();
  }

  componentDidMount() {
    this.loadCurrentUser();
  }

  render() {
    const EditUserForm = ({ fields }) => {
      //console.log(fields);
      const [form] = Form.useForm();
      const formItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 8,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 16,
          },
        },
      };
      const tailLayout = {
        wrapperCol: {
          offset: 8,
          span: 8,
        },
      };

      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };

      return (
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          layout="horizontal"
          size="large"
          form={form}
          name="edit"
          onFinish={onFinish}
          initialValues={fields}
          scrollToFirstError
        >
          <Form.Item
            name="id"
            label="Id"
            rules={[
              {
                required: true,
                message: "Please input the id!",
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Please input the email!",
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="first_name"
            label="Frist Name"
            rules={[
              {
                required: true,
                message: "Please input the first name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[
              {
                required: true,
                message: "Please input the last name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {/* {fields.role_id === 1 ? (
            <>
              <Form.Item
                name="Request_Create_Sandbox_ICON20200618"
                label="ดาวน์โหลดเอกสาร"
              >
                <Link
                  to="/Request_Create_Sandbox_ICON20200618.xlsx"
                  target="_black"
                  download="Request_Create_Sandbox_ICON20200618"
                >
                  <h2 style={{textDecoration: "underline" }}>ดาวน์โหลด</h2>
                </Link>
              </Form.Item>
            </>
          ) : (
            ""
          )}
          <Form.Item
            name="new_password"
            label="New Password"
            rules={[
              {
                required: false,
                // message: "Please input the name of company token!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label="Confirm New Password"
            rules={[
              {
                required: false,
                // message: "Please input the name of company token!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item> */}
          <Form.Item name="company_id" label="Terminal Id" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      );
    };
    const EditPasswordForm = ({ fields }) => {
      //console.log(fields);
      const [form] = Form.useForm();
      const formItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 8,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 16,
          },
        },
      };
      const tailLayout = {
        wrapperCol: {
          offset: 8,
          span: 8,
        },
      };

      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };

      return (
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          layout="horizontal"
          size="large"
          form={form}
          name="edit"
          onFinish={onFinishPassword}
          initialValues={fields}
          scrollToFirstError
        >
          <Form.Item
            name="old_password"
            label="Old Password"
            rules={[
              {
                required: true,
                message: "Please input your old password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="new_password"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
                // message: "Please input the name of company token!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm_password"
            label="Confirm New Password"
            dependencies={["new_password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item name="company_id" label="Terminal Id" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name="id" label="Id" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      );
    };
    return (
      <>
        <Breadcrumb
          itemRender={itemRender}
          items={[
            {
              title: <HomeOutlined />,
              path: "/",
            },
            {
              title: "Setting",
            },
          ]}
          style={{ margin: "16px 0" }}
        ></Breadcrumb>
        <Row>
          {/* <Col
            span={8}
            style={{
              paddingRight: "12px",
            }}
          >
            <Card
              style={{
                minHeight: "50vh",
                textAlign: "center",
                padding: 24,
                backgroundColor: "#fff",
              }}
            >
              {this.state.currentUser ? (
                <div className="user-profile">
                  <div className="user-details">
                    <div
                      className="user-avatar"
                      style={{
                        width: "100%",
                        marginBottom: "20px",
                        textAlign: "center",
                      }}
                    >
                      <Avatar
                        className="user-avatar-circle"
                        style={{
                          backgroundColor: getAvatarColor(
                            this.state.currentUser.first_name
                          ),
                        }}
                        size={84}
                      >
                        {this.state.currentUser.first_name[0].toUpperCase()}
                      </Avatar>
                    </div>
                    <br></br>
                    <div
                      className="user-summary"
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      <div className="full-name">
                        {this.state.currentUser.first_name} &nbsp;
                        {this.state.currentUser.last_name}
                      </div>
                      <div className="username">
                        {this.state.currentUser.email}
                      </div>
                      <div className="user-joined">
                        Joined {formatDate(this.state.currentUser.created_date)}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </Card>
          </Col> */}
          <Col span={24}>
            <Card
              style={{
                minHeight: "80vh",
                textAlign: "center",
                padding: 24,
                backgroundColor: "#fff",
              }}
            >
              {this.state.currentUser ? (
                <Tabs
                  defaultActiveKey="1"
                  size="large"
                  type="card"
                  items={[
                    {
                      key: "1",
                      label: "Info",
                      children: <EditUserForm fields={this.state.fields} />,
                    },
                    {
                      key: "3",
                      label: "Change Password",
                      children: <EditPasswordForm fields={this.state.fields} />,
                    },
                  ]}
                ></Tabs>
              ) : (
                ""
              )}
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const openNotificationWithIcon = (type, msg) => {
  //console.log(msg);
  if (type === "success") {
    notification[type]({
      message: "Success",
      description: msg,
    });
  } else {
    notification[type]({
      message: "Error",
      description: msg,
    });
  }
};

const onFinish = (values) => {
  console.log("Received values of form: ", values);
  UsersService.editUserCompany(values)
    .then((data) => {
      openNotificationWithIcon("success", data.data.message);
    })
    .catch((err) => {
      openNotificationWithIcon("error", err.message);
      //console.log(err);
    });
};
const onFinishPassword = (values) => {
  console.log("Received values of form: ", values);
  UsersService.editUserCompanyPassword(values)
    .then((data) => {
      console.log(data);
      openNotificationWithIcon("success", data.data.message);
    })
    .catch((err) => {
      openNotificationWithIcon("error", err.message);
      console.log(err.data);
    });
};

export default Profile;

import { HomeOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  notification,
  Radio,
  Row,
  Select,
  Tabs,
} from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../components/css/Profile.css";
import AuthService from "../../services/auth.service";
import UsersService from "../../services/user.service";
import CompanyService from "../../services/company.service";
import TermsService from "../../services/terms.service";
import itemRender from "../../utils/itemsRender";
const { Content, Header } = Layout;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      currentUser: null,
      isLoading: false,
      fields: null,
      companyData: null,
      terms: null,
      inputValiableTypeText: false,
      inputValiableTypeLink: true,
      termType: "text",
    };
    if (!localStorage.getItem("user")) {
      this.props.history("/login");
    }

    this.loadCurrentUser = this.loadCurrentUser.bind(this);
  }

  loadCurrentUser() {
    this.setState({
      isLoading: true,
    });
    const user = AuthService.getCurrentUser();

    if (user) {
      UsersService.getUserAndCompanyById(user.id)
        .then((data) => {
          CompanyService.getCompanyById(data.data.company_id)
            .then((companydata) => {
              this.setState({
                currentUser: AuthService.getCurrentUser(),
                isAdminBoard: user.role,
                isAuthenticated: true,
                isLoading: false,
                fields: data.data,
                companyData: companydata.data,
              });
            })
            .catch((err) => {
              console.log(err);
            });

          TermsService.getTermsByCompanyId(data.data.company_id)
            .then((termsdata) => {
              this.setState({
                terms: termsdata.data,
              });
              if (termsdata.data.Type === "text") {
                this.setState({
                  termType: "text",
                  inputValiableTypeText: false,
                  inputValiableTypeLink: true,
                });
              } else {
                this.setState({
                  termType: "link",
                  inputValiableTypeText: true,
                  inputValiableTypeLink: false,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  onChangeTerms = (values) => {
    if (values.target.value === "text") {
      this.setState({
        termType: values.target.value,
        inputValiableTypeText: false,
        inputValiableTypeLink: true,
      });
    } else {
      this.setState({
        termType: values.target.value,
        inputValiableTypeText: true,
        inputValiableTypeLink: false,
      });
    }
  };

  onFinishTerms = (values) => {
    console.log("Received values of form: ", values);
    values.Type = this.state.termType;
    values.CompanyId = this.state.companyData.Id;
    if (values.Id) {
      TermsService.editTerms(values)
        .then((data) => {
          console.log(data);
          openNotificationWithIcon("success", data.data.message);
        })
        .catch((err) => {
          openNotificationWithIcon("error", err.message);
          console.log(err.data);
        });
    } else {
      TermsService.createTerms(values)
        .then((data) => {
          console.log(data);
          openNotificationWithIcon("success", data.data.message);
        })
        .catch((err) => {
          openNotificationWithIcon("error", err.message);
          console.log(err.data);
        });
    }
  };

  onFinish = (values) => {
    console.log("Received values of form: ", values);
    CompanyService.editCompany(values)
      .then((data) => {
        openNotificationWithIcon("success", data.data.message);
      })
      .catch((err) => {
        openNotificationWithIcon("error", err.message);
        //console.log(err);
      });
  };
  onFinishBank = (values) => {
    console.log("Received values of form bank: ", values);
    UsersService.editUserCompanyBank(values)
      .then((data) => {
        openNotificationWithIcon("success", data.data.message);
      })
      .catch((err) => {
        openNotificationWithIcon("error", err.message);
        //console.log(err);
      });
  };

  handleLogout() {
    AuthService.logout();
  }

  componentDidMount() {
    this.loadCurrentUser();
  }

  render() {
    const EditUserForm = ({ fields }) => {
      //console.log(fields);
      const [form] = Form.useForm();
      const formItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 8,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 16,
          },
        },
      };
      const tailLayout = {
        wrapperCol: {
          offset: 8,
          span: 8,
        },
      };

      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };

      return (
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          layout="horizontal"
          size="large"
          form={form}
          name="edit"
          onFinish={this.onFinish}
          initialValues={fields}
          scrollToFirstError
        >
          <Form.Item
            name="Id"
            label="Id"
            rules={[
              {
                required: true,
                message: "Please input the id!",
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            name="Name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please input the name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="Alias"
            label="Alias"
            rules={[
              {
                required: true,
                message: "Please input the Alias!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="Id" label="Id" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      );
    };
    const EditBankForm = ({ fields }) => {
      //console.log(fields);
      const [form] = Form.useForm();
      const formItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 8,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 16,
          },
        },
      };
      const tailLayout = {
        wrapperCol: {
          offset: 8,
          span: 8,
        },
      };

      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };

      return (
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 8 }}
          layout="horizontal"
          size="large"
          form={form}
          name="editBank"
          onFinish={this.onFinishBank}
          initialValues={fields}
          scrollToFirstError
        >
          {fields.role_id === 1 ? (
            <>
              <Form.Item name="merchant_id" label="Merchant Id">
                <Input />
              </Form.Item>
              <Form.Item name="terminal_id" label="Terminal Id">
                <Input />
              </Form.Item>
              <Form.Item name="publickey_prod" label="Public Key Prod">
                <Input />
              </Form.Item>
              <Form.Item name="secretkey_prod" label="Secret key Prod">
                <Input />
              </Form.Item>
            </>
          ) : (
            ""
          )}
          <Form.Item name="company_id" label="Terminal Id" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name="id" label="Id" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      );
    };

    const EditTermsForm = ({ fields }) => {
      //console.log(fields);
      const [form] = Form.useForm();
      const formItemLayout = {
        labelCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 8,
          },
        },
        wrapperCol: {
          xs: {
            span: 24,
          },
          sm: {
            span: 16,
          },
        },
      };
      const tailLayout = {
        wrapperCol: {
          offset: 8,
          span: 8,
        },
      };

      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };

      return (
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 12 }}
          layout="horizontal"
          size="large"
          form={form}
          name="edit"
          onFinish={this.onFinishTerms}
          initialValues={{
            ["Value"]: fields.Value,
            ["CompanyId"]: fields.CompanyId,
            ["Id"]: fields.Id,
          }}
          scrollToFirstError
        >
          <Form.Item name="Type" label="Type">
            <Radio.Group
              onChange={this.onChangeTerms}
              defaultValue={this.state.termType}
              size="large"
            >
              <Radio value="text">Text</Radio>
              <Radio value="link">Link</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            disabled={this.state.inputValiableTypeText}
            hidden={this.state.inputValiableTypeText}
            name="Value"
            label="Text"
            rules={[
              {
                required: true,
                message: "Please input your Text!",
                // message: "Please input the name of company token!",
              },
            ]}
          >
            <Input.TextArea rows={10} />
            {/* <CKEditor
                    editor={ ClassicEditor }
                    data={fields.Value}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={ ( event, editor ) => {
                        const data = editor.getData();
                        console.log( { event, editor, data } );
                    } }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                /> */}
          </Form.Item>
          <Form.Item
            disabled={this.state.inputValiableTypeLink}
            hidden={this.state.inputValiableTypeLink}
            name="Value"
            label="Text"
            rules={[
              {
                required: true,
                message: "Please input your Text!",
                // message: "Please input the name of company token!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="CompanyId" label="Company" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name="Id" label="Id" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      );
    };
    return (
      <>
        <Breadcrumb
          itemRender={itemRender}
          items={[
            {
              title: <HomeOutlined />,
              path: "/",
            },
            {
              title: "Setting",
            },
          ]}
          style={{ margin: "16px 0" }}
        ></Breadcrumb>
        <Row>
          <Col span={24}>
            <Card
              style={{
                minHeight: "80vh",
                textAlign: "center",
                padding: 24,
                backgroundColor: "#fff",
              }}
            >
              {this.state.currentUser ? (
                <Tabs
                  defaultActiveKey="1"
                  size="large"
                  type="card"
                  items={[
                    {
                      key: "1",
                      label: "Company",
                      children: (
                        <EditUserForm fields={this.state.companyData} />
                      ),
                    },
                    {
                      key: "2",
                      label: "KBank Setting",
                      children: <EditBankForm fields={this.state.fields} />,
                    },
                    {
                      key: "3",
                      label: "Terms & Conditions",
                      children: <EditTermsForm fields={this.state.terms} />,
                    },
                  ]}
                ></Tabs>
              ) : (
                ""
              )}
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

const openNotificationWithIcon = (type, msg) => {
  //console.log(msg);
  if (type === "success") {
    notification[type]({
      message: "Success",
      description: msg,
    });
  } else {
    notification[type]({
      message: "Error",
      description: msg,
    });
  }
};

export default Profile;

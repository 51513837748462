import React, { Key, useEffect, useState } from "react";
import { useCalendarEvents } from "./useCalendarEvents";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import SignInButton from "./loginButtom";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  message,
  DatePicker,
  Layout,
  Breadcrumb,
  Card,
  Select,
  Calendar,
  List,
  Modal,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  HomeOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import itemRender from "../../utils/itemsRender";
import moment from "moment";

const { Content, Header } = Layout;

const CalendarPage = () => {
  const [getPeriodList, setGetPeriodList] = useState([]);
  const [startPeriod, setStartPeriod] = useState("");
  const [endPeriod, setEndPeriod] = useState("");
  const [periodId, setPeriodId] = useState("");
  const [defaultValue, setDefaultValue] = useState(null);
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  var startDatePicker = Date();
  var endDatePicker = Date();
  var calendarArray = [];
  // var periodArrayForView: { title:any; periodId: any}[];

  const { accounts, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const calendarEvents = useCalendarEvents();
  let tokenCoworking = sessionStorage.getItem("coworkingToken");

  const handleStartDateChange = (date) => {
    setStartPeriod(date.format("YYYY-MM-DD"));
  };
  const handleEndDateChange = (date) => {
    setEndPeriod(date.format("YYYY-MM-DD"));
  };

  const calculateHoursDifference = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffInMilliseconds = endDate.getTime() - startDate.getTime();
    return diffInMilliseconds / (1000 * 60 * 60);
  };

  const onFinish = async (values) => {
    const { token } = await login(values.username, values.password);
    if (token) {
      sessionStorage.setItem("coworkingToken", token);
      window.location.reload();
      console.log("Token received:", token);
    } else {
      message.error("Login failed, please check your credentials");
    }
    console.log("Received values of form: ", values);
  };

  const login = async (username, password) => {
    try {
      const response = await fetch(
        "https://coworking-api.iconframework.com/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: username,
            password: password,
            IpAddress: "58.97.86.85",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      return {};
    }
  };

  async function sendCoworking() {
    periodList();
    //createNewTimesheet(20,"test","day13",9);
  }

  async function periodList() {
    try {
      const response = await fetch(
        "https://coworking-api.iconframework.com/timesheet/period",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(tokenCoworking),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("ddddddd :" + data);
      const firstArray = data[1];
      //const firstArray = data[0];  // ล่าสุด
      //const firstArray = data[1];  //ก่อน Pirod ล่าสุด

      timesheetList(firstArray.periodId);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  async function periodListForView() {
    try {
      const response = await fetch(
        "https://coworking-api.iconframework.com/timesheet/period",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(tokenCoworking),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      //console.log(data);
      let dataOption = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        dataOption.push({
          value: element.periodId,
          label:
            "Period " +
            moment(element.startPeriod).format("DD/MM/YYYY") +
            " - " +
            moment(element.endPeriod).format("DD/MM/YYYY"),
          start: moment(element.startPeriod).format("DD/MM/YYYY"),
          end: moment(element.endPeriod).format("DD/MM/YYYY"),
          originalstart: element.startPeriod,
          originalend: element.endPeriod,
        });
      }
      //console.log(dataOption);
      if (dataOption.length > 0) {
        setDefaultValue(dataOption[0].value);
      }
      setGetPeriodList(dataOption);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  async function timesheetList(periodId) {
    try {
      const response = await fetch(
        "https://coworking-api.iconframework.com/timesheet/owner",
        {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(tokenCoworking),
          },
          body: JSON.stringify({
            periodId: periodId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      insertToCoworking(data, periodId);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  async function insertToCoworking(timesheetList, periodId) {
    let calendarArray = calendarEvents.map((event) => ({
      title: event.subject,
      date: "day" + new Date(event.start.dateTime).getDate(),
      hours: calculateHoursDifference(event.start.dateTime, event.end.dateTime),
    }));
    checkInsert(calendarArray, timesheetList, periodId);
  }

  async function checkInsert(calendarArray, timesheetArray, periodId) {
    if (calendarArray.length > 0) {
      if (timesheetArray.length > 0) {
        var calendarItem = calendarArray[0];
        var timesheetItem = timesheetArray[0];
        setMeetingType(
          timesheetItem["timeSheetId"],
          calendarItem["title"],
          calendarItem["date"],
          calendarItem["hours"]
        );
        console.log("Update MeetingType : " + timesheetItem["timeSheetId"]);
        calendarArray.shift();
        timesheetArray.shift();
        if (calendarArray.length > 0) {
          checkInsert(calendarArray, timesheetArray, periodId);
        }
      } else {
        var calendarItem = calendarArray[0];
        createNewTimesheet(
          periodId,
          calendarItem["title"],
          calendarItem["date"],
          calendarItem["hours"]
        );
        console.log("Create : " + calendarItem["title"]);
        calendarArray.shift();
        if (calendarArray.length > 0) {
          checkInsert(calendarArray, timesheetArray, periodId);
        }
      }
    }
  }

  async function createNewTimesheet(periodId, topic, date, hours) {
    try {
      const response = await fetch(
        "https://coworking-api.iconframework.com/timesheet/owner/create",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(tokenCoworking),
          },
          body: JSON.stringify({
            periodId: periodId,
            topic: topic,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setMeetingType(data.response["timeSheetId"], topic, date, hours);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  async function setMeetingType(timesheedID, topic, date, hours) {
    try {
      const response = await fetch(
        "https://coworking-api.iconframework.com/timesheet/owner/update",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(tokenCoworking),
          },
          body: JSON.stringify({
            timeSheetId: timesheedID,
            topic: topic,
            companyId: 94,
            activityId: 39,
            productId: 41,
            projectId: 103,
            description: null,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setHour(timesheedID, date, hours);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  const updateValueForKey = (jsonData, key, newValue) => {
    return {
      ...jsonData,
      [key]: newValue,
    };
  };

  const addItem = (title, date, hours) => {
    const newItem = {
      title: title,
      date: date,
      hours: hours,
    };
    calendarArray.push(newItem);
  };

  async function setHour(timesheedId, date, hours) {
    var thisJson = {
      timeSheetId: timesheedId,
      day1: 0,
      day2: 0,
      day3: 0,
      day4: 0,
      day5: 0,
      day6: 0,
      day7: 0,
      day8: 0,
      day9: 0,
      day10: 0,
      day11: 0,
      day12: 0,
      day13: 0,
      day14: 0,
      day15: 0,
      day16: 0,
      day17: 0,
      day18: 0,
      day19: 0,
      day20: 0,
      day21: 0,
      day22: 0,
      day23: 0,
      day24: 0,
      day25: 0,
      day26: 0,
      day27: 0,
      day28: 0,
      day29: 0,
      day30: 0,
      day31: 0,
    };
    const newJson = updateValueForKey(thisJson, date, hours);

    try {
      const response = await fetch(
        "https://coworking-api.iconframework.com/timesheet/owner/update-date",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + String(tokenCoworking),
          },
          body: JSON.stringify(newJson),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }

  const logoutCowking = () => {
    sessionStorage.removeItem("coworkingToken");
    window.location.reload();
  };

  const logoutAzure = () => {
    instance.logoutRedirect();
  };

  const removeURLParameter = (url) => {
    const regStartExp = new RegExp(`[?&]start(=[^&#]*)?`, "i");
    const regEndExp = new RegExp(`[?&]end(=[^&#]*)?`, "i");
    const regPeriod = new RegExp(`[?&]period(=[^&#]*)?`, "i");
    const newURL = url.replace(regStartExp, "");
    const newURL2 = newURL.replace(regEndExp, "");
    const newURL3 = newURL2.replace(regPeriod, "");
    return newURL3;
  };

  const calendarSearch = (start, end, periodId) => {
    const currentURL = window.location.href;
    const newURL = removeURLParameter(`${currentURL}`);
    window.location.href = `${newURL}?start=${moment(start).format(
      "YYYY-MM-DD"
    )}&end=${moment(end).format("YYYY-MM-DD")}&period=${periodId}`;
  };

  const getEventDate = (event) => {
    const { dateTime } = event;
    const date = new Date(dateTime);
    //const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    const formattedDate = `Date : ${date
      .getDate()
      .toString()
      .padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}`;
    return formattedDate;
  };

  const LoginAzure = () => {
    if (!isAuthenticated) {
      return (
        <div className="App">
          <h1>Please sign in to view your Microsoft Calendar 365 events.</h1>
          <SignInButton />
        </div>
      );
    }
    return (
      <div className="CalendarPage">
        <h1>Microsoft Calendar 365 Events</h1>
        <Row justify="center">
          <Col span={6} style={{ padding: "20px" }}>
            <DatePicker
              onChange={handleStartDateChange}
              format="YYYY-MM-DD"
              placeholder="Start a date"
              defaultValue={startPeriod}
            />
          </Col>
          <Col span={6} style={{ padding: "20px" }}>
            <DatePicker
              onChange={handleEndDateChange}
              format="YYYY-MM-DD"
              placeholder="End a date"
              defaultValue={endPeriod}
            />
          </Col>
          <Col span={6} style={{ padding: "20px" }}>
            <Button type="primary" onClick={calendarSearch}>
              Search
            </Button>
          </Col>
          <Col span={6} style={{ padding: "20px" }}>
            <Button type="primary" onClick={logoutAzure}>
              Azure Logout
            </Button>
          </Col>
        </Row>

        <Calendar cellRender={dateCellRender} onSelect={onSelect} />
        <Modal
          title={`Events on ${
            selectedDate ? selectedDate.format("YYYY-MM-DD") : ""
          }`}
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
              OK
            </Button>,
          ]}
        >
          {selectedEvents.length > 0 ? (
            <List
              bordered
              dataSource={selectedEvents}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  {item.subject}
                  {" : ( "}
                  {calculateHoursDifference(
                    item.start.dateTime,
                    item.end.dateTime
                  )}
                  {" ) "}
                  Hours.
                </List.Item>
              )}
            />
          ) : (
            <p>No events for this date.</p>
          )}
        </Modal>
        {/* <ul>
          {calendarEvents.map((event) => (
            <li key={event.id}>
              {event.subject} <br></br> {getEventDate(event.start)} ={" "}
              {calculateHoursDifference(
                event.start.dateTime,
                event.end.dateTime
              )}{" "}
              hours
            </li>
          ))}
        </ul> */}
      </div>
    );
  };

  const LoginCoworking = () => {
    if (!tokenCoworking) {
      return (
        <Row justify="center" align="middle">
          <Col>
            <Form
              name="login-form"
              onFinish={onFinish}
              initialValues={{
                remember: true,
              }}
              size="large"
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input prefix={<UserOutlined />} placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      );
    }
    return (
      <div>
        <h1>Token</h1>
        <div>
          <Input value={tokenCoworking} />
        </div>
        <br></br>
        <br></br>
        <div>
          <Button type="primary" onClick={logoutCowking}>
            Coworking Logout
          </Button>
        </div>
      </div>
    );
  };

  const handleSelectPeriod = (value, option) => {
    //console.log(value, option);
    setPeriodId(option?.value);
    setStartPeriod(option?.start);
    setEndPeriod(option?.end);
    calendarSearch(option?.originalstart, option?.originalend, option?.value);
  };

  const dateCellRender = (value) => {
    const formattedDate = value.format("YYYY-MM-DD");
    const dayEvents = calendarEvents.filter(
      (event) =>
        moment(event.start.dateTime).format("YYYY-MM-DD") === formattedDate
    );

    return (
      <ul>
        {dayEvents.map((event) => (
          <li key={event.id}>{event.subject}</li>
        ))}
      </ul>
    );
  };

  const onSelect = (date) => {
    setSelectedDate(date);
    const formattedDate = date.format("YYYY-MM-DD");
    const filteredEvents = calendarEvents.filter(
      (event) =>
        moment(event.start.dateTime).format("YYYY-MM-DD") === formattedDate
    );
    console.log(filteredEvents);
    setSelectedEvents(filteredEvents);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    periodListForView();
  }, []);

  return (
    <>
      <Breadcrumb
        itemRender={itemRender}
        items={[
          {
            title: <HomeOutlined />,
            path: "/",
          },
          {
            title: (
              <>
                <FieldTimeOutlined /> <span>Timesheet</span>
              </>
            ),
            path: "/",
          },
        ]}
        style={{ margin: "16px 0" }}
      ></Breadcrumb>
      <Card ghost="false" title="TimeSheet" subtitle="This is a timesheet">
        <Row justify="center">
          <Col span={18} style={{ padding: "20px" }}>
            {LoginCoworking()}
          </Col>
          <Col span={6} style={{ padding: "20px" }}>
            <Button type="primary" onClick={sendCoworking}>
              Send to Coworking
            </Button>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24} style={{ padding: "20px" }}>
            <Select
              style={{
                width: "50%",
              }}
              size={"large"}
              placeholder="Select a period"
              options={getPeriodList}
              onChange={handleSelectPeriod}
              defaultValue={defaultValue}
            />
          </Col>
        </Row>
        <Row justify="center" style={{ minHeight: "100vh" }}>
          <Col span={24} style={{ padding: "20px" }}>
            {LoginAzure()}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CalendarPage;

import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Table, Modal, Row, Col, Button } from 'antd';
import { SearchCustom } from "../components/SearchCustom";

const TableCustom = ({ setModalAdd, ...props }) => {
    const { columns, action, data , addon } = props.config || ""
    const { visible, buttonTitle } = props.buttonAdd || ""
    const [rowData, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [search, setSearch] = useState("")
    const [colFilter, setColFilter] = useState("name")



    const setModal = (e) => {
        setModalAdd(e)
    }

    const conditionEffect = () => {

        if(search !== ""){
            return filterSearch()
          }

        return setData(data)

    }

    ///search
    const filterSearch = () => {

        let temp = [...data]

        let result = temp.filter(ele => ele[`${colFilter}`].toLowerCase().includes(search.toLowerCase()))

        setFilterData([...result])


    }


    useEffect(() => {
        conditionEffect()
    }, [data , search])

    return (
        <div>


            <Row justify={"space-between"}>
                <SearchCustom setSearch={setSearch} setColFilter={setColFilter} addon={addon} />
                {
                    visible === true && <Button type="primary" onClick={() => setModal(true)}  >{buttonTitle}</Button>
                }
            </Row>
            <Row>
                <Col span={24}>
                    <Table rowKey={e => e.richMenuId} dataSource={search == "" ? rowData : filterData} columns={columns} />
                </Col>
            </Row>

        </div>
    )
}

export default TableCustom

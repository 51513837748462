import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Layout,
  Modal,
  Form,
  Input,
  Alert,
  Select,
  Card,
  Row,
  Col,
} from "antd";
import TableCredentials from "../../components/TableUsers";
import {
  HomeOutlined,
  UserOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import UserService from "../../services/user.service";
import CompanyService from "../../services/company.service";
import AuthService from "../../services/auth.service";
import { Link } from "react-router-dom";
import itemRender from "../../utils/itemsRender";
import { SearchCustom } from "../../components/SearchCustom";

const { Content, Header } = Layout;
const { Option } = Select;

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  onAlert,
  message,
}) => {
  const [form] = Form.useForm();
  const [dataCompany, setDataCompany] = useState([]);
  const getCurrentUser = AuthService.getCurrentUser();
  const getCompany = () => {
    CompanyService.getCompany()
      .then((data) => {
        setDataCompany(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (getCurrentUser.role === 3) {
      getCompany();
    }
  }, []);

  return (
    <Modal
      open={visible}
      title="Create a new user"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      {onAlert
        ? [<Alert message={message} type="error" showIcon />, <br></br>]
        : ""}
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Please input the email!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="username"
          label="Username"
          rules={[
            {
              required: true,
              message: "Please input the username!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input the passwordn!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[
            {
              required: true,
              message: "Please input the first name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[
            {
              required: true,
              message: "Please input the last name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {getCurrentUser.role === 3 ? (
          <Form.Item
            name="company"
            label="Company"
            rules={[
              {
                required: true,
                message: "Please select the server type of company token!",
              },
            ]}
          >
            <Select>
              {dataCompany.map((d) => (
                <Option key={d.id}>{d.name}</Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          ""
        )}
        <Form.Item
          name="role"
          label="Role"
          rules={[
            {
              required: true,
              message: "Please select the server type of company token!",
            },
          ]}
        >
          <Select>
            <Select.Option value={1}>Admin [1]</Select.Option>
            <Select.Option value={2}>User [2]</Select.Option>
            {getCurrentUser.role === 3 ? (
              <Select.Option value={3}>Super Admin [3]</Select.Option>
            ) : (
              ""
            )}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

function ListCredentials() {
  const [visible, setVisible] = useState(false);
  const [statusAlert, setStatusAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [colFilter, setColFilter] = useState("username");
  const [dataCompany, setDataCompany] = useState([]);
  const getCompany = () => {
    CompanyService.getCompany()
      .then((data) => {
        setDataCompany(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onCreate = (values) => {
    console.log("Received values of form: ", values);
    UserService.createUser(values)
      .then((data) => {
        setVisible(false);
        window.location.reload(false);
      })
      .catch((err) => {
        setMessageAlert("Failed! Email is already in use!");
        setStatusAlert(true);
        console.log(err);
      });
  };

  const onChange = (value) => {
    //setColFilter("company_id");
    setSearch2(value);
  };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <>
      <Breadcrumb
        itemRender={itemRender}
        items={[
          {
            title: <HomeOutlined />,
            path: "/",
          },
          {
            title: (
              <>
                <UserOutlined /> <span>Users</span>
              </>
            ),
          },
        ]}
        style={{ margin: "16px 0" }}
      ></Breadcrumb>
      <Card
        ghost="false"
        title="Users"
        subtitle="This is a users"
        extra={
          <Button type="primary" onClick={() => setVisible(true)}>
            <PlusCircleOutlined /> Add New User
          </Button>
        }
      >
        <Row>
          <Col span={16}>
            <SearchCustom
              setSearch={setSearch}
              setColFilter={setColFilter}
              addon={"user"}
            />
          </Col>
          <Col span={3}>Company :</Col>
          <Col span={5}>
            <Select
              showSearch
              placeholder="Select a company"
              optionFilterProp="children"
              onChange={onChange}
              style={{
                width: "100%",
              }}
              filterOption={(input, option) =>
                (option?.children ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {dataCompany.map((d) => (
                <Option key={d.id}>{d.name}</Option>
              ))}
            </Select>
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col span={24}>
            <TableCredentials
              search={search}
              col={colFilter}
              search2={search2}
            />
          </Col>
        </Row>
      </Card>
      <CollectionCreateForm
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
        onAlert={statusAlert}
        message={messageAlert}
      />
    </>
  );
}

export default ListCredentials;

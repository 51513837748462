import React from "react";
import { Layout, Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import itemRender from "../utils/itemsRender";
// import axios from "axios";
const { Content, Header } = Layout;

const Home = () => {
  // const data= {
  //     project: "1",
  //     model: "1",
  //     token: "57a0a7f3-e7ec-4e04-98e8-47c63cec6bbb",
  //   }
  // axios({
  //   url: "http://localhost:4001/company/verify",
  //   method: "post",
  //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
  //   data: "project=1&model=1&token=57a0a7f3-e7ec-4e04-98e8-47c63cec6bbb",
  // }).then((res) => {
  //   console.log(res);
  // });

  return (
    <>
      <Breadcrumb
        itemRender={itemRender}
        items={[
          {
            title: (
              <>
                <HomeOutlined /> Home
              </>
            ),
            path: "/home",
          },
        ]}
        style={{ margin: "16px 0" }}
      ></Breadcrumb>
      <div
        className="container"
        style={{
          minHeight: "80vh",
          textAlign: "center",
          padding: 24,
          backgroundColor: "#fff",
        }}
      >
        <h1 style={{ fontSize: "3em" }}>Welcome</h1>
        <br></br>
        <h1 style={{ fontSize: "2em" }}>Admin Portal</h1>
      </div>
    </>
  );
};

export default Home;

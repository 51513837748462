import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Space,
  Switch,
  Table,
  Typography,
  Select,
} from "antd";
import React, { useEffect, useState, useReducer } from "react";
import MasterDataService from "../../services/masterdata.service";
import { createAction, createReducer } from "@reduxjs/toolkit";
import _ from "lodash";

const { confirm } = Modal;
const { Paragraph } = Typography;
const setLoading = createAction("SET_LOADING");
const setOpenModal = createAction("SET_OPEN_MODAL");
const setCloseModal = createAction("SET_CLOSE_MODAL");
const setData = createAction("SET_DATA");
const setSaving = createAction("SET_SAVING");
const setSearchValue = createAction("SET_SEARCH_VALUE");

const initState = {
  loading: false,
  search: {
    project_type: "h",
  },
  dataSorce: [],
  modal: {
    visible: false,
    data: null,
    selectedKey: null,
  },
  saving: false,
};

const reducer = createReducer(initState, (builder) => {
  builder.addCase(setLoading, (state, { payload }) => {
    state.loading = payload;
  });

  builder.addCase(setOpenModal, (state, { payload }) => {
    state.modal.visible = true;
    state.modal.data = payload?.data ? payload.data : null;
    state.modal.selectedKey = payload?.key ? payload.key : null;
  });

  builder.addCase(setCloseModal, (state) => {
    state.modal.visible = false;
    state.modal.data = null;
    state.modal.selectedKey = null;
  });

  builder.addCase(setData, (state, { payload }) => {
    state.dataSorce = payload;
  });

  builder.addCase(setSaving, (state, { payload }) => {
    state.saving = payload;
  });

  builder.addCase(setSearchValue, (state, { payload }) => {
    state.search = { ...state.search, ...payload };
  });
});

function TableBankEndMenu({ id }) {
  // state table
  const [state, dispatch] = useReducer(reducer, initState);
  // const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [modalMultiStatus, setModalMultiStatus] = useState(false);
  const [modalCreateServiceMenu, setModalCreateServiceMenu] = useState(false);
  const [modalEditServiceMenu, setModalEditServiceMenu] = useState({
    visible: false,
    id: "",
    path: "",
    name: "",
    parent_id: "",
    seq: 0,
    status: "",
  });
  const [statusValue, setStatusValue] = useState("Active");

  // fetch data
  const fetch = async (params = { page: 1, pageSize: 10 }) => {
    try {
      setLoading(true);
      const ret = await MasterDataService.getBackEndMenu(id);
      // check rem error
      if (ret.status !== 200) {
        throw { message: ret.data.message };
      }

      const data = _.orderBy(ret.data, "seq");
      setLoading(false);
      dispatch({ type: "SET_DATA", payload: data });
    } catch (error) {
      return;
    }
  };

  const showConfirmStatus = (id, status) => {
    let dataValue = {
      id: id,
      status: status === "active" ? "deactive" : "active",
    };
    confirm({
      title: "Comfirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to change status?",
      onOk() {
        MasterDataService.updateStatusBackEndMenu(dataValue)
          .then((data) => {
            setLoading(true);
            openNotificationWithIcon("success");
            fetch();
            return new Promise((resolve, reject) => {
              setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            }).catch(() => console.log("Oops errors!"));
          })
          .catch((err) => {
            openNotificationWithIcon("error");
            console.log("Oops errors!");
          });
      },
      onCancel() {},
    });
  };
  const showConfirmDelete = (id, status) => {
    let dataValue = {
      id: id,
      status: status === "Active" ? "Deactive" : "Active",
    };
    confirm({
      title: "Comfirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to change status?",
      onOk() {
        MasterDataService.deleteBackEndMenu(dataValue)
          .then((data) => {
            setLoading(true);
            openNotificationWithIcon("success");
            fetch();
            return new Promise((resolve, reject) => {
              setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            }).catch(() => console.log("Oops errors!"));
          })
          .catch((err) => {
            openNotificationWithIcon("error");
            console.log("Oops errors!");
          });
      },
      onCancel() {},
    });
  };

  const onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onChangeStatus = (e) => {
    console.log("radio checked", e.target.value);
    setStatusValue(e.target.value);
  };

  const CollectionStatusForm = ({ visible, onCreate, onCancel }) => {
    const [form] = Form.useForm();
    return (
      <Modal
        open={visible}
        title="Change Status"
        okText="Save"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          onCreate();
        }}
      >
        <Radio.Group onChange={onChangeStatus} value={statusValue}>
          <Radio value={"Active"}>Active</Radio>
          <Radio value={"Deactive"}>Deactive</Radio>
        </Radio.Group>
      </Modal>
    );
  };

  const onSubmitStatus = () => {
    let dataValue = {
      Id: selectedRowKeys,
      Status: statusValue,
    };
    MasterDataService.updateStatusBackEndMenu(dataValue)
      .then((data) => {
        setLoading(true);
        openNotificationWithIcon("success");
        setModalMultiStatus(false);
        setSelectedRowKeys([]);
        fetch();
      })
      .catch((err) => {
        openNotificationWithIcon("error");
        console.log("Oops errors!");
      });
  };

  const CollectionCreateServiceMenuForm = ({ visible, onCreate, onCancel }) => {
    const [form] = Form.useForm();
    return (
      <Modal
        open={visible}
        title="Create a new LINE OA My Home Service"
        okText="Create"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            Select: "",
          }}
        >
          <Form.Item
            name="path"
            label="Path"
            rules={[
              {
                required: true,
                message: "Please input the path!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please input the name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="parent_id"
            label="Parent Id "
            rules={[
              {
                required: true,
                message: "Please input the parent_id!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="seq"
            label="ลำดับการแสดง"
            rules={[
              {
                required: true,
                message: "Please input the Seq!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const CollectionEditServiceMenuForm = ({ visible, onEdit, onCancel }) => {
    const [form] = Form.useForm();
    return (
      <Modal
        open={visible}
        title="Update a LINE OA My Home Service"
        okText="Update"
        cancelText="Cancel"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onEdit(values);
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            id: modalEditServiceMenu.id,
            path: modalEditServiceMenu.path,
            name: modalEditServiceMenu.name,
            parent_id: modalEditServiceMenu.parent_id,
            seq: modalEditServiceMenu.seq,
            status: modalEditServiceMenu.status,
          }}
        >
          <Form.Item
            name="path"
            label="Path"
            rules={[
              {
                required: true,
                message: "Please input the Path!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please input the Name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="parent_id"
            label="Parent Id"
            rules={[
              {
                required: true,
                message: "Please input the Parent Id!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="seq"
            label="ลำดับการแสดง"
            rules={[
              {
                required: true,
                message: "Please input the Seq!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: "Please input the Status!",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="active">Active</Radio>
              <Radio value="deactive">Deactive</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="id"
            rules={[
              {
                required: true,
                message: "Please input the id!",
              },
            ]}
          >
            <Input hidden />
          </Form.Item>
        </Form>
      </Modal>
    );
  };

  const onCreate = (values) => {
    let dataValue = {
      path: values.path,
      name: values.name,
      parent_id: values.parent_id,
      seq: values.seq,
    };
    MasterDataService.createBackEndMenu(dataValue)
      .then((data) => {
        setLoading(true);
        openNotificationWithIcon("success");
        setModalCreateServiceMenu(false);
        fetch();
      })
      .catch((err) => {
        openNotificationWithIcon("error");
        console.log("Oops errors!");
      });
  };

  const onEdit = (values) => {
    console.log(values);
    let dataValue = {
      id: values.id,
      path: values.path,
      name: values.name,
      parent_id: values.parent_id,
      seq: values.seq,
      status: values.status,
    };
    MasterDataService.editBackEndMenu(dataValue)
      .then((data) => {
        setLoading(true);
        openNotificationWithIcon("success");
        setModalEditServiceMenu({ visible: false });
        fetch();
      })
      .catch((err) => {
        openNotificationWithIcon("error");
        console.log("Oops errors!");
      });
  };

  const handleClickSort = (type, index) => {
    let data = [...state.dataSorce];
    let from = data[index];
    let to = data[type === "up" ? index - 1 : index + 1];

    data[index] = to;
    data[type === "up" ? index - 1 : index + 1] = from;
    let datachange = { menu: [] };
    for (let ii = 0; ii < data.length; ii++) {
      const element = data[ii];

      datachange.menu.push({ key: element.id, seq: ii + 1 });
    }
    console.log(datachange);
    MasterDataService.editBackEndMenuSeq(datachange)
      .then((data) => {
        setLoading(true);
        openNotificationWithIcon("success");
        setModalEditServiceMenu({ visible: false });
        fetch();
      })
      .catch((err) => {
        openNotificationWithIcon("error");
        console.log("Oops errors!");
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <Space style={{ float: "right", margin: "10px" }}>
        <Button
          key="1"
          type="primary"
          onClick={() => setModalMultiStatus(true)}
        >
          <EditOutlined /> Change Status
        </Button>
        <Button
          key="2"
          type="primary"
          onClick={() => setModalCreateServiceMenu(true)}
        >
          <PlusCircleOutlined /> Create BackEnd Menu
        </Button>
      </Space>
      <CollectionStatusForm
        key="multistatus"
        visible={modalMultiStatus}
        onCreate={onSubmitStatus}
        onCancel={() => {
          setModalMultiStatus(false);
        }}
      />
      <CollectionCreateServiceMenuForm
        key="mult"
        visible={modalCreateServiceMenu}
        onCreate={onCreate}
        onCancel={() => {
          setModalCreateServiceMenu(false);
        }}
      />
      <CollectionEditServiceMenuForm
        key="mult2"
        visible={modalEditServiceMenu.visible}
        onEdit={onEdit}
        onCancel={() => {
          setModalEditServiceMenu({ visible: false });
        }}
      />
      <Table
        rowSelection={rowSelection}
        //columns={columns}
        rowKey={(record) => record.path}
        dataSource={state.dataSorce}
        // pagination={pagination}
        loading={loading}
        // onChange={handleTableChange}
        scroll={true}
        className="table-row-pointer"
      >
        <Table.Column title="Id" dataIndex="id" />
        <Table.Column title="Path" dataIndex="path" />
        <Table.Column title="Name" dataIndex="name" />
        <Table.Column title="Parent Id " dataIndex="parent_id" />
        <Table.Column
          title="Status"
          align="center"
          dataIndex="status"
          width="120px"
          render={(text, record) => (
            <Switch
              defaultChecked={text === "active" ? true : false}
              checkedChildren="Active"
              unCheckedChildren="Deactive"
              onClick={() => showConfirmStatus(record.id, record.status)}
              //onChange={showConfirm(text.Id)}
            />
          )}
        />
        <Table.Column
          align="center"
          dataIndex="id"
          render={(text, record, index) => (
            <Space size="small">
              <Button
                size="small"
                type="link"
                disabled={index === 0}
                onClick={() => handleClickSort("up", index)}
              >
                <ArrowUpOutlined />
              </Button>
              <Button
                size="small"
                type="link"
                disabled={index + 1 === state.data?.length}
                onClick={() => handleClickSort("down", index)}
              >
                <ArrowDownOutlined />
              </Button>
              <Button
                size="small"
                type="link"
                onClick={() =>
                  setModalEditServiceMenu({
                    visible: true,
                    id: record.id,
                    path: record.path,
                    name: record.name,
                    parent_id: record.parent_id,
                    seq: record.seq,
                    status: record.status,
                  })
                }
              >
                แก้ไข
              </Button>
              <Button
                size="small"
                type="link"
                onClick={() => showConfirmDelete(record.id, record.status)}
              >
                ลบ
              </Button>
            </Space>
          )}
        />
      </Table>
    </>
  );
}
// ••••••••••••••••••••

const openNotificationWithIcon = (type) => {
  if (type === "success") {
    notification[type]({
      message: "Success",
      description: "Successfully.",
    });
  } else {
    notification[type]({
      message: "Error",
      description: "Something when wrong.",
    });
  }
};

export default TableBankEndMenu;

import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Layout,
  Modal,
  Form,
  Input,
  Select,
  Card,
} from "antd";
import TableCredentials from "../../components/TableCredentials";
import {
  HomeOutlined,
  SafetyCertificateOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import CredentialsService from "../../services/credentials.service";
import CompanyService from "../../services/company.service";
import AuthService from "../../services/auth.service";
import { Link } from "react-router-dom";
import { SearchCustom } from "../../components/SearchCustom";
import itemRender from "../../utils/itemsRender";

const { Content, Header } = Layout;
const { Option } = Select;

const CollectionCreateForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [dataCompany, setDataCompany] = useState([]);
  const [dataCompanyById, setDataCompanyById] = useState([]);
  const getCurrentUser = AuthService.getCurrentUser();
  const getCompany = () => {
    CompanyService.getCompany()
      .then((data) => {
        setDataCompany(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCompanyById = () => {
    CompanyService.getCompanyById(getCurrentUser.company_id)
      .then((data) => {
        setDataCompanyById(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (getCurrentUser.role === 3) {
      getCompany();
    }
    getCompanyById();
  }, []);

  return (
    <Modal
      open={visible}
      title="Create a new credential"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          Select: "",
          merchant_id: dataCompanyById.MerchantId || "",
          terminal_id: dataCompanyById.TerminalId || "",
          type: "prod",
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input the name of company token!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="origins"
          label="Origins"
          rules={[
            {
              required: true,
              message: "Please input the origins of company token!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {getCurrentUser.role === 3 ? (
          <Form.Item
            name="company"
            label="Company"
            rules={[
              {
                required: true,
                message: "Please select the server type of company token!",
              },
            ]}
          >
            <Select>
              {dataCompany.map((d) => (
                <Option key={d.id}>{d.name}</Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          ""
        )}
        <Form.Item
          name="type"
          label="Server Type"
          rules={[
            {
              required: true,
              message: "Please select the server type of company token!",
            },
          ]}
          style={{ display: "none" }}
        >
          <Select defaultValue="prod">
            {/* <Select.Option value="dev">Dev</Select.Option>
            <Select.Option value="uat">Uat</Select.Option> */}
            <Select.Option value="prod">Prod</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="merchant_id"
          label="Merchant Id"
          style={{ display: "none" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="terminal_id"
          label="Terminal Id"
          style={{ display: "none" }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

function ListCredentials() {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [colFilter, setColFilter] = useState("Name");
  const onCreate = (values) => {
    CredentialsService.createCredentials(values)
      .then((data) => {
        setVisible(false);
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Breadcrumb
        itemRender={itemRender}
        items={[
          {
            title: <HomeOutlined />,
            path: "/",
          },
          {
            title: (
              <>
                <SafetyCertificateOutlined /> Credentials
              </>
            ),
          },
        ]}
        style={{ margin: "16px 0" }}
      ></Breadcrumb>
      <Card
        ghost="false"
        title="Credentials"
        extra={
          <Button type="primary" onClick={() => setVisible(true)}>
            <PlusCircleOutlined /> Add New Credential
          </Button>
        }
      >
        <div
          className="container"
          style={{
            minHeight: "80vh",
            textAlign: "center",
            padding: 24,
            backgroundColor: "#fff",
          }}
        >
          <SearchCustom
            setSearch={setSearch}
            setColFilter={setColFilter}
            addon={"credentials"}
          />
          <br />
          <br />
          <TableCredentials search={search} col={colFilter} />
        </div>
      </Card>
      <CollectionCreateForm
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </>
  );
}

export default ListCredentials;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Modal, notification, Tag } from "antd";
import CompanyService from "../services/company.service";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const { confirm } = Modal;

function TableCompany(prop) {
  const { search, col } = prop;

  const columnsCompany = [
    {
      title: "Name",
      dataIndex: "name",
      key: "Name",
    },
    {
      title: "Url",
      dataIndex: "url",
      key: "Url",
    },
    {
      title: "Alias",
      key: "alias",
      dataIndex: "alias",
    },
    {
      title: "Storage Size (GB)",
      key: "storage_size",
      dataIndex: "storage_size",
      align: "center",
    },
    {
      title: "Smart Sale",
      key: "is_smartsale",
      dataIndex: "is_smartsale",
      align: "center",
      render: (text) => {
        if (text === false) {
          return (
            <Tag color={"red"} key={text}>
              No
            </Tag>
          );
        } else {
          return (
            <Tag color={"green"} key={text}>
              Yes
            </Tag>
          );
        }
      },
    },
    {
      title: "Sync User REM",
      key: "is_sync_user_rem",
      dataIndex: "is_sync_user_rem",
      align: "center",
      render: (text) => {
        if (text === false) {
          return (
            <Tag color={"red"} key={text}>
              No
            </Tag>
          );
        } else {
          return (
            <Tag color={"green"} key={text}>
              Yes
            </Tag>
          );
        }
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      align: "center",
      render: (text) => {
        if (text === "Deactive") {
          return (
            <Tag color={"red"} key={text}>
              {text.toUpperCase()}
            </Tag>
          );
        } else {
          return (
            <Tag color={"green"} key={text}>
              {text.toUpperCase()}
            </Tag>
          );
        }
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      render: (text, record) => (
        <span>
          <Link to={"/company/" + text.id} style={{ marginRight: 16 }}>
            <EditOutlined /> Edit
          </Link>
          <a onClick={() => showConfirm(text.id)}>
            <DeleteOutlined /> Delete
          </a>
        </span>
      ),
    },
  ];
  // state column
  const [columns, setColumns] = useState(columnsCompany);

  // state table
  const [company, setCompany] = useState([]);
  const [filterCompany, setFilterCompany] = useState([]);
  // const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  ///search
  const filterSearch = () => {
    let temp = [...company];
    // let result = temp.filter(ele=> console.log( ele[`${col}`].toLowerCase()) )

    let result = temp.filter((ele) =>
      ele[`${col}`].toLowerCase().includes(search.toLowerCase())
    );

    setFilterCompany([...result]);
  };

  // fetch data
  const fetch = async (params = { page: 1, pageSize: 10 }) => {
    try {
      setLoading(true);
      const ret = await CompanyService.getCompany();

      // check rem error
      if (ret.status !== 200) {
        throw { message: ret.data.message };
      }

      const data = ret.data;
      setLoading(false);
      setCompany(data);

      if (data.length > 0) {
        setColumns(columnsCompany);
      }
    } catch (error) {
      return;
    }
  };

  const showConfirm = (id) => {
    let dataValue = {
      id: id,
    };
    confirm({
      title: "Comfirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to delete company?",
      onOk() {
        CompanyService.deleteCompany(dataValue)
          .then((data) => {
            setLoading(true);
            openNotificationWithIcon("success");
            fetch();
            return new Promise((resolve, reject) => {
              setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            }).catch(() => console.log("Oops errors!"));
          })
          .catch((err) => {
            openNotificationWithIcon("error");
            console.log("Oops errors!");
          });
      },
      onCancel() {},
    });
  };

  const conditionEffect = (e) => {
    if (search !== "") {
      return filterSearch();
    }

    return fetch();
  };

  useEffect(() => {
    conditionEffect();
  }, [search]);

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={search !== "" ? filterCompany : company}
        // pagination={pagination}
        loading={loading}
        // onChange={handleTableChange}
        scroll={false}
        className="table-row-pointer"
      />
    </>
  );
}
// ••••••••••••••••••••

const openNotificationWithIcon = (type) => {
  if (type === "success") {
    notification[type]({
      message: "Success",
      description: "Deleted company successfully.",
    });
  } else {
    notification[type]({
      message: "Error",
      description: "Something when wrong.",
    });
  }
};

export default TableCompany;

import React from "react";

export const initialAuthState = {
  loading: false,
  error: null,
  authenticated: false,
  data: {},
};

export const authReducer = (state, action) => {
  switch (action.type) {
    case "USER_LOGIN":
      return { ...state, loading: true };
    case "USER_LOGIN_SUCCESS":
      return {
        ...state,
        loading: false,
        authenticated: true,
        data: action.payload,
        error: null,
      };
    case "USER_LOGIN_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "USER_LOGOUT":
      return initialAuthState;
    default:
      return state;
  }
};

export const AuthContext = React.createContext({});

import React from "react";
import { Layout } from "antd";

const Footer = Layout.Footer;

function AppFooter() {
    return (
      <Footer style={{ textAlign: "center" }}>Admin Portal © 2024 v2.0.0</Footer>
    );
  }

export default AppFooter;

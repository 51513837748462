import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

class CompanyService {
  getCompany() {
    return axios.get(API_URL + "/company", {
      headers: authHeader(),
    });
  }

  getCompanyById(id) {
    return axios.get(API_URL + "/company/" + id, {
      headers: authHeader(),
    });
  }

  createCompany(params) {
    return axios({
      url: `${API_URL}/company`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  editCompany(params) {
    return axios({
      url: `${API_URL}/company/edit`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  deleteCompany(params) {
    return axios({
      url: `${API_URL}/company/delete`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  saveTheme(params) {
    return axios({
      url: `${API_URL}/company/theme`,
      method: "POST",
      data: { theme: params },
      headers: authHeader(),
    });
  }

  saveCompanyProfile(params) {
    return axios({
      url: `${API_URL}/company/profile`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  getLineOaByCompanyId(id) {
    return axios.get(API_URL + "/company/lineoa/" + id, {
      headers: authHeader(),
    });
  }

  updateStatusLineOaCompany(params) {
    return axios({
      url: `${API_URL}/company/lineoa`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  multiUpdateStatusLineOaCompany(params) {
    return axios({
      url: `${API_URL}/company/lineoa/multistatus`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  lineOaCompanyUnlinkMultiMenu(params) {
    return axios({
      url: `${API_URL}/company/lineoa/multiunlink`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  lineOaCompanyLinkMultiMenu(params) {
    return axios({
      url: `${API_URL}/company/lineoa/multilink`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  findAllMyHomeByCompanyId(params) {
    return axios({
      url: `${API_URL}/company/master/linemyhomeservice/all/${params}`,
      method: "GET",
      headers: authHeader(),
    });
  }

  findOneMyHomeByCompanyId(params) {
    return axios({
      url: `${API_URL}/company/master/linemyhomeservice/${params}`,
      method: "GET",
      headers: authHeader(),
    });
  }

  createMyHomeByCompanyId(params) {
    return axios({
      url: `${API_URL}/company/master/linemyhomeservice/create`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  editMyHomeByCompanyId(params) {
    return axios({
      url: `${API_URL}/company/master/linemyhomeservice/edit`,
      method: "PUT",
      data: params,
      headers: authHeader(),
    });
  }

  removeMyHomeByCompanyId(params) {
    return axios({
      url: `${API_URL}/company/master/linemyhomeservice/delete`,
      method: "DELETE",
      data: params,
      headers: authHeader(),
    });
  }

  multiDeleteMyHomeByCompanyId(params) {
    return axios({
      url: `${API_URL}/company/master/linemyhomeservice/multidelete`,
      method: "DELETE",
      data: params,
      headers: authHeader(),
    });
  }
}

export default new CompanyService();

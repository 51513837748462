import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
//import Header from "../components/Header";
//import Sider from "../components/Sider";
import Footer from "../components/Footer";
import { Layout, message, Menu, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  HomeOutlined,
  UserOutlined,
  SafetyCertificateOutlined,
  TableOutlined,
  BankOutlined,
  BgColorsOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  LayoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  FieldTimeOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import AuthService from "../services/auth.service";

const { Content, Sider, Header } = Layout;

function MasterLayout({ children }) {
  const [authContext, dispatchAuth] = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);
  const history = useNavigate();
  const currentUser = authContext.data;
  let menuItems;
  if (authContext.authenticated) {
    menuItems = [
      {
        key: "user-info",
        label: (
          <Link to={`/profile/${currentUser.first_name}`}>
            <div className="user-full-name-info">
              {` `}
              <UserOutlined />
              {` `}
              {currentUser.first_name}
              {` `}
              {currentUser.last_name}
            </div>
          </Link>
        ),
      },
      {
        key: "logout",
        label: "Logout",
        onClick: () => {
          localStorage.removeItem(process.env.REACT_APP_USER_KEY);
          localStorage.removeItem("app-theme");
          dispatchAuth({ type: "USER_LOGOUT" });
          history("/login");
        },
      },
    ];
  }
  let keypath =
    "/" +
    window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
  function handleMenuClick({ key }) {
    if (key === "logout") {
      localStorage.removeItem(process.env.REACT_APP_USER_KEY);
      dispatchAuth({ type: "USER_LOGOUT" });
      history("/login");
      // window.location.reload();
    } else {
      history(key);
    }
  }
  let companyTheme = localStorage.getItem(process.env.REACT_APP_USER_KEY);
  if (companyTheme && JSON.parse(companyTheme).company_id !== 0) {
    let themeResult = JSON.parse(companyTheme).theme;
    let initialValue = JSON.parse(themeResult);
    let vars = {};

    try {
      vars = Object.assign(
        {},
        initialValue,
        JSON.parse(localStorage.getItem("app-theme"))
      );
    } catch (e) {
      vars = initialValue;
    } finally {
      window.less
        .modifyVars(vars)
        .then(() => {
          localStorage.setItem("app-theme", JSON.stringify(vars));
        })
        .catch((error) => {
          message.error(`Failed to update theme`);
        });
    }
  }

  const loadUser = async () => {
    if (localStorage.getItem(process.env.REACT_APP_USER_KEY)) {
      if (!authContext.authenticated) {
        try {
          const user = AuthService.getCurrentUser();
          dispatchAuth({ type: "USER_LOGIN_SUCCESS", payload: user });
        } catch (error) {
          dispatchAuth({ type: "USER_LOGOUT" });

          localStorage.removeItem(process.env.REACT_APP_USER_KEY);
          history("/login");
        }
      }
    } else {
      history("/login");
    }
  };

  useEffect(() => {
    loadUser();
  });

  return (
    <Layout>
      <Sider
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsed={collapsed}
        //breakpoint="lg"
        className="site-layout-background"
        style={{
          height: "100vh",
          position: "fixed",
          zIndex: 1000,
        }}
      >
        <Link to={`/`}>
          <div
            className=""
            style={{
              display: "inline-block",
              height: `(${!collapsed ? "32px" : "40px"})`,
              margin: "15px",
              width: `calc(100% - 30px)`,
              background: "rgba(255, 255, 255, 0.2)",
              textAlign: "center",
              padding: "3px",
            }}
          >
            {!collapsed ? (
              <h1 style={{ color: "#ffffff", margin: 0 }}>Admin Portal</h1>
            ) : (
              <p style={{ color: "#ffffff", margin: 0 }}>Admin Portal</p>
            )}
          </div>
        </Link>
        <Menu
          theme="dark"
          onClick={handleMenuClick}
          mode="inline"
          defaultSelectedKeys={[keypath]}
          style={{ borderRight: 0 }}
          items={[
            {
              key: "/home",
              label: (
                <>
                  <HomeOutlined /> <span>Home</span>
                </>
              ),
            },
            {
              key: "/credentials",
              label: (
                <>
                  <SafetyCertificateOutlined /> <span>Credentials</span>
                </>
              ),
            },
            currentUser.role !== 3 && {
              key: `/setting/${currentUser.first_name}`,
              label: "Setting",
            },
            currentUser.role === 3 && {
              key: `/units`,
              label: (
                <>
                  <TableOutlined /> <span>Units</span>
                </>
              ),
            },
            currentUser.role === 3 && {
              key: `/users`,
              label: (
                <>
                  <UserOutlined /> <span>Users</span>
                </>
              ),
            },
            currentUser.role === 3 && {
              key: `/company`,
              label: (
                <>
                  <BankOutlined /> <span>Company</span>
                </>
              ),
            },
            currentUser.role === 1 && {
              key: `/theme`,
              label: (
                <>
                  <BgColorsOutlined /> <span>Theme</span>
                </>
              ),
            },
            currentUser.role === 3 && {
              key: `/customer`,
              label: (
                <>
                  <UsergroupAddOutlined /> <span>Customer</span>
                </>
              ),
            },
            currentUser.role === 3 && {
              key: `/masterdata`,
              label: (
                <>
                  <SettingOutlined /> <span>Master Data</span>
                </>
              ),
            },
            currentUser.role === 3 && {
              key: `/richmenu`,
              label: (
                <>
                  <LayoutOutlined /> <span>Rich Menu</span>
                </>
              ),
            },
            currentUser.role === 3 && {
              key: `/liffapp`,
              label: (
                <>
                  <LayoutOutlined /> <span>Liff App</span>
                </>
              ),
            },
            currentUser.role === 3 && {
              key: `/timesheet`,
              label: (
                <>
                  <FieldTimeOutlined /> <span>Timesheet</span>
                </>
              ),
            },
            currentUser.role === 3 && {
              key: `/logapi`,
              label: (
                <>
                  <SwapOutlined /> <span>Log API</span>
                </>
              ),
            },
          ]}
        ></Menu>
      </Sider>
      <Layout
        className="site-layout"
        style={{ marginLeft: collapsed ? 80 : 200 }}
      >
        <Header
          className="site-layout-background"
          style={{
            position: "fixed",
            zIndex: 10,
            width: `calc(100% - ${collapsed ? 80 : 200}px)`,
            paddingLeft: 0,
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "18px",
              width: 64,
              height: 64,
              borderColor: "white",
            }}
          />
          <Menu
            theme="light"
            mode="horizontal"
            style={{ marginLeft: "auto" }}
            items={menuItems}
          />
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "64px 16px 0",
            //padding: 24,
            minHeight: "calc(100vh - 64px)",
          }}
        >
          {children}
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
}

export default MasterLayout;

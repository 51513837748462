import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Layout,
  Modal,
  Form,
  Input,
  Select,
  Checkbox,
  Card,
} from "antd";
import TableCredentials from "../../components/TableCompany";
import {
  HomeOutlined,
  BankOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import CompanyService from "../../services/company.service";
import { Link } from "react-router-dom";
import { SearchCustom } from "../../components/SearchCustom";
import itemRender from "../../utils/itemsRender";
const { Content, Header } = Layout;
const { Option } = Select;

const CollectionCreateForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      open={visible}
      title="Create a new company"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          Select: "",
        }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input the name of company token!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="url"
          label="Url"
          rules={[
            {
              required: true,
              message: "Please input the url of company token!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="api_prod"
          label="API Prod"
          rules={[
            {
              required: true,
              message: "Please input the name of company token!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="alias"
          label="Alias"
          rules={[
            {
              required: true,
              message: "Please input the name of company token!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="product_type"
          label="ProductType"
          rules={[
            {
              required: true,
              message: "Please input the origins of company!",
            },
          ]}
        >
          <Select>
            <Option value="remlite">remlite</Option>
            <Option value="pmlite">pmlite</Option>
            <Option value="rental">rental</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="is_sync_user_rem"
          label="Is Sync User REM"
          rules={[
            {
              required: true,
              message: "Please select Is Sync User REM!",
            },
          ]}
        >
          <Select>
            <Option value={false}>No</Option>
            <Option value={true}>Yes</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="is_smartsale"
          label="Is Smart Sale"
          rules={[
            {
              required: true,
              message: "Please select Is Smart Sale!",
            },
          ]}
        >
          <Select>
            <Option value={false}>No</Option>
            <Option value={true}>Yes</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="developer_code"
          label="Developer Code"
          rules={[
            {
              required: true,
              message: "Please input the developer code!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="storage_size"
          label="Storage Size (GB)"
          rules={[
            {
              required: true,
              message: "Please input the Storage Size (GB)!",
            },
          ]}
        >
          <Input type="number" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

function ListCredentials() {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [colFilter, setColFilter] = useState("name");

  const onCreate = (values) => {
    CompanyService.createCompany(values)
      .then((data) => {
        setVisible(false);
        window.location.reload(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Breadcrumb
        itemRender={itemRender}
        items={[
          {
            title: <HomeOutlined />,
            path: "/",
          },
          {
            title: (
              <>
                <BankOutlined /> <span>Company</span>
              </>
            ),
          },
        ]}
        style={{ margin: "16px 0" }}
      ></Breadcrumb>
      <Card
        ghost="false"
        title="Company"
        subtitle="This is a company"
        extra={[
          <Button key="1" type="primary" onClick={() => setVisible(true)}>
            <PlusCircleOutlined /> Add New Company
          </Button>,
          <CollectionCreateForm
            key=""
            visible={visible}
            onCreate={onCreate}
            onCancel={() => {
              setVisible(false);
            }}
          />,
        ]}
      >
        <SearchCustom
          setSearch={setSearch}
          setColFilter={setColFilter}
          addon={"company"}
        />
        <br />
        <br />
        <TableCredentials search={search} col={colFilter} />
      </Card>
    </>
  );
}

export default ListCredentials;

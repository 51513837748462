import React, { useReducer } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import NotFound from "./common/NotFound";
import {
  AuthContext,
  authReducer,
  initialAuthState,
} from "./context/AuthContext";
// import "./components/css/App.css";
import BlankLayout from "./layouts/BlankLayout";
import MasterLayout from "./layouts/MasterLayout";
import Company from "./pages/company";
import CompanyEdit from "./pages/company/edit";
import Credentials from "./pages/credentials";
import CredentialsEdit from "./pages/credentials/edit";
import Customer from "./pages/customer";
import CustomerEdit from "./pages/customer/edit";
import MasterData from "./pages/masterdata";
//import Theme from "./pages/theme";
import Units from "./pages/units";
import UserEdit from "./pages/user/edit";
import Login from "./pages/user/login";
import Users from "./pages/user/management";
import Profile from "./pages/user/profile";
import Register from "./pages/user/register";
import Setting from "./pages/user/setting";
import Welcome from "./pages/welcome";
import RichMenu from "./pages/richmenu";
import LiffApp from "./pages/liffapp";
import Timesheet from "./pages/timesheet";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./pages/timesheet/authConfig";
import LogAPI from "./pages/logapi";

function App() {
  const useAuthState = useReducer(authReducer, initialAuthState);
  return (
    <AuthContext.Provider value={useAuthState}>
      <Router>
        <Routes>
          <Route
            key={"home"}
            path="/"
            exact
            element={
              localStorage.getItem(process.env.REACT_APP_USER_KEY) ? (
                <MasterLayout>
                  <Welcome />
                  <Navigate to="/home" />
                </MasterLayout>
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/login"
            exact
            element={
              <BlankLayout>
                <Login />
              </BlankLayout>
            }
          />
          <Route
            path="/register"
            exact
            element={
              <BlankLayout>
                <Register />
              </BlankLayout>
            }
          />
          <Route
            path="/error"
            exact
            element={
              <BlankLayout>
                <NotFound />
              </BlankLayout>
            }
          />
          <Route
            key={"home"}
            path="/home"
            exact
            element={
              <MasterLayout>
                <Welcome />
              </MasterLayout>
            }
          />
          <Route
            key={"credentials"}
            path="/credentials"
            exact
            element={
              <MasterLayout>
                <Credentials />
              </MasterLayout>
            }
          />
          <Route
            key={"credentials/:token"}
            path="/credentials/:token"
            exact
            element={
              <MasterLayout>
                <CredentialsEdit />
              </MasterLayout>
            }
          />
          <Route
            key={"units"}
            path="/units"
            exact
            element={
              <MasterLayout>
                <Units />
              </MasterLayout>
            }
          />
          <Route
            key={"users"}
            path="/users"
            exact
            element={
              <MasterLayout>
                <Users />
              </MasterLayout>
            }
          />
          <Route
            key={"users/edit/:id"}
            path="/users/edit/:id"
            exact
            element={
              <MasterLayout>
                <UserEdit />
              </MasterLayout>
            }
          />
          <Route
            key={"profile/:name"}
            path="/profile/:name"
            exact
            element={
              <MasterLayout>
                <Profile />
              </MasterLayout>
            }
          />
          <Route
            key={"company"}
            path="/company"
            exact
            element={
              <MasterLayout>
                <Company />
              </MasterLayout>
            }
          />
          <Route
            key={"company/:id"}
            path="/company/:id"
            exact
            element={
              <MasterLayout>
                <CompanyEdit />
              </MasterLayout>
            }
          />
          <Route
            key={"setting/:id"}
            path="/setting/:id"
            exact
            element={
              <MasterLayout>
                <Setting />
              </MasterLayout>
            }
          />
          <Route
            key={"customer"}
            path="/customer"
            exact
            element={
              <MasterLayout>
                <Customer />
              </MasterLayout>
            }
          />
          <Route
            key={"customer/:id"}
            path="/customer/:id"
            exact
            element={
              <MasterLayout>
                <CustomerEdit />
              </MasterLayout>
            }
          />
          <Route
            key={"masterdata"}
            path="/masterdata"
            exact
            element={
              <MasterLayout>
                <MasterData />
              </MasterLayout>
            }
          />
          <Route
            key={"richmenu"}
            path="/richmenu"
            exact
            element={
              <MasterLayout>
                <RichMenu />
              </MasterLayout>
            }
          />
          <Route
            key={"liffapp"}
            path="/liffapp"
            exact
            element={
              <MasterLayout>
                <LiffApp />
              </MasterLayout>
            }
          />
          <Route
            key={"timesheet"}
            path="/timesheet"
            exact
            element={
              <MasterLayout>
                <MsalProvider instance={msalInstance}>
                  <Timesheet />
                </MsalProvider>
              </MasterLayout>
            }
          />
          <Route
            key={"logapi"}
            path="/logapi"
            exact
            element={
              <MasterLayout>
                <LogAPI />
              </MasterLayout>
            }
          />
        </Routes>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;

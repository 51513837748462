import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

class LogsService {
  getLogs(params) {
    return axios({
      url: `${API_URL}/logs`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }
}

export default new LogsService();

import { useState, useEffect } from "react";
import { Client } from "@microsoft/microsoft-graph-client";
import { msalInstance } from "./authConfig";

const graphScopes = ["openid", "profile", "User.Read", "Calendars.Read"];

async function getAccessToken() {
  const account = msalInstance.getAllAccounts()[0];

  if (!account) {
    throw new Error("No account found.");
  }

  const response = await msalInstance.acquireTokenSilent({
    account: account,
    scopes: graphScopes,
  });
  return response.accessToken;
}

function getCurrentMonthStartEndDates() {
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const endOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  return { startOfMonth, endOfMonth };
}

async function getCalendarEvents(accessToken) {
  const { startOfMonth, endOfMonth } = getCurrentMonthStartEndDates();
  const current = new Date();
  let start;
  let end;
  let params = new URLSearchParams(window.location.search);
  let startdate = params.get("start");
  let enddate = params.get("end");

  if (startdate == null || enddate == null) {
    if (current.getDate() > 15) {
      start = new Date(startOfMonth.setDate(16));
      end = endOfMonth;
    } else {
      start = startOfMonth;
      end = new Date(endOfMonth.setDate(15));
    }
    start = start.toISOString();
    end.setDate(end.getDate() + 1);
    end = end.toISOString();
  } else {
    start = new Date(startdate).toISOString();
    end = new Date(enddate);
    end.setDate(end.getDate() + 1);
    end = end.toISOString();
  }

  const client = Client.init({
    authProvider: (done) => {
      done(null, accessToken);
    },
  });

  let events = await client
    .api("/me/events")
    .select("subject,start,end")
    .filter(`start/dateTime gt '${start}' and end/dateTime le '${end}'`)
    .top(1000)
    .skip(1)
    .get();
  return events;
}

export function useCalendarEvents() {
  const [calendarEvents, setCalendarEvents] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessToken();
        console.log("This token : " + accessToken);
        const events = await getCalendarEvents(accessToken);

        setCalendarEvents(events.value);
      } catch (error) {
        console.error("Failed to fetch calendar events:", error);
      }
    })();
  }, []);

  return calendarEvents;
}

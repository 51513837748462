import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

class CustomerService {
  getCustomer() {
    return axios({
      url: `${API_URL}/customer/all`,
      method: "GET",
      headers: authHeader(),
    });
  }

  getCustomerById(id) {
    return axios({
      url: `${API_URL}/customer/findbyid/${id}`,
      method: "GET",
      headers: authHeader(),
    });
  }

  getCustomerAddressById(id) {
    return axios({
      url: `${API_URL}/customer/findaddressbyid/${id}`,
      method: "GET",
      headers: authHeader(),
    });
  }

  createCustomer(params) {
    return axios({
      url: `${API_URL}/customer/create`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  editCustomer(params) {
    return axios({
      url: `${API_URL}/customer/update`,
      method: "PUT",
      data: params,
      headers: authHeader(),
    });
  }

  editCustomerAddress(params) {
    return axios({
      url: `${API_URL}/customer/update/address`,
      method: "PUT",
      data: params,
      headers: authHeader(),
    });
  }

  editCustomerPassword(params) {
    return axios({
      url: `${API_URL}/customer/update/password`,
      method: "PUT",
      data: params,
      headers: authHeader(),
    });
  }

  deleteCustomer(params) {
    return axios({
      url: `${API_URL}/customer/delete`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }
}

export default new CustomerService();

import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

class RichMenuService {
  /*
   * Start LINE OA My Richmenu Service 
   */
  async getRichMenuList(e) {
    let data = await axios({
      url: `${API_URL}/richmenu/list`,
      method: "POST",
      data: {
        access_token: e.access_token
      },
      headers: authHeader(),
    })

    return data
  }


  async deleteRichMenu(e) {
    let data = await axios({
      url: `${API_URL}/richmenu/delete`,
      method: "POST",
      data: {
        access_token: e.access_token,
        richMenuId: e.richId
      },
      headers: authHeader(),
    })
    return data
  }



  async createRichMenu(e) {
    let data = await axios({
      url: `${API_URL}/richmenu/create`,
      method: "POST",
      data: {
        access_token: e.access_token,
        data: JSON.parse(e.json)
      },
      headers: authHeader(),
    })
    return data
  }

  async findAllChannelAccess() {
    let data = await axios.get(`${API_URL}/richmenu/findAll`, {
      headers: authHeader(),
    })
    return data
  }

  async createChannelAccess(e) {

    let data = await axios({
      url: `${API_URL}/richmenu/channel`,
      method: "POST",
      data: {
        access_token: e.access_token,
        channel_name: e.channel_name
      },
      headers: authHeader(),
    })
    return data
  }



  async deleteChannelAccess(e) {
    let data = await axios({
      url: `${API_URL}/richmenu/deleteChannel`,
      method: "DELETE",
      data: {
        access_token: e.access_token,
        channel_name: e.channel_name
      },
      headers: authHeader(),
    })
    return data
  }

  async setRichMenuDefault(e) {
    let data = await axios({
      url: `${API_URL}/richmenu/setDefault`,
      method: "POST",
      data: {
        access_token: e.access_token,
        richMenuId: e.richMenuId
      },
      headers: authHeader(),
    })
    return data
  }

  async getRichMenuDefault(e) {
    let data = await axios({
      url: `${API_URL}/richmenu/getDefault`,
      method: "POST",
      data: {
        access_token: e.access_token
      },
      headers: authHeader(),
    })

    return data
  }

  async uploadImgRichMenu(e) {


   let promise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {

          let data = await axios({
            url: `${API_URL}/richmenu/uploadImg`,
            method: "POST",
            data: {
              access_token: e.access_token,
              richMenuId: e.richMenuId,
              form: event.target.result
            },
            headers: authHeader(),
          })

          resolve(data);
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };


      reader.readAsDataURL(e.form);


    });

    return promise


  }

  /*
   *End LINE OA My Richmenu Service 
   */
}

export default new RichMenuService();

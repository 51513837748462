import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "fae9b10f-6321-4424-b687-5795b1df7d2e",
    authority: "https://login.microsoftonline.com/a1bf4ac7-a9c9-4e58-8a06-6a0baafa488d",
    redirectUri: window.location.origin,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
import { Table, Modal } from "antd";
import UnitsService from "../services/units.service";
import { ExclamationCircleOutlined, UnlockOutlined } from "@ant-design/icons";
import moment from "moment";

const { confirm } = Modal;

function TableUnit(prop) {
  // const history = useHistory();
  // state column
  const [columns, setColumns] = useState(columnsUnits);
  const { search, col } = prop
  // state table
  const [units, setUnits] = useState([]);
  const [filterUnits, setFilterUnits] = useState([])


  ///search
  const filterSearch = () => {

    let temp = [...units]
    let result = temp.filter(ele => ele[`${col}`] !== null ? ele[`${col}`].toLowerCase().includes(search.toLowerCase()) : null)

    setFilterUnits([...result])


  }


  // const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);

  // fetch data
  const fetch = async (params = { page: 1, pageSize: 10 }) => {
    try {
      setLoading(true);
      const ret = await UnitsService.getUnits();
      
      // check rem error
      if (ret.status !== 200) {
        throw { message: ret.data.message };
      }

      const data = ret.data;
      setLoading(false);
      setUnits(data);

      if (data.length > 0) {
        setColumns(columnsUnits);
      }
    } catch (error) {
      return;
    }
  };

  const conditionEffect = (e) => {

    if (search !== "") {
      return filterSearch()
    }
    return fetch()

  }


  useEffect(() => {
    conditionEffect()
  }, [search]);

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={search !== "" ? filterUnits : units}

        // pagination={pagination}
        loading={loading}
        // onChange={handleTableChange}
        scroll={false}
        className="table-row-pointer"
      />
    </>
  );
}

const columnsUnits = [
  {
    title: "Project",
    dataIndex: "project_name",
    key: "project_name",
  },
  {
    title: "Unit Number",
    dataIndex: "unit_number",
    key: "unit_number",
    render: (text) => <a>{text}</a>,
  },
  {
    title: "Lock Date",
    dataIndex: "lock_date",
    key: "lock_date",
    render: (text) => (
      <span>{moment(text).format("Do MMMM YYYY, h:mm:ss a")}</span>
    ),
  },
  {
    title: "Total Date",
    dataIndex: "lock_date",
    key: "total_date",
    render: (text) => <span>{moment(text, "YYYYMMDD").fromNow()}</span>,
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
  },
  {
    title: "Action",
    key: "action",
    render: (text) => (
      <a onClick={() => showConfirm(text.customer_id, text.unit_id)}>
        <UnlockOutlined /> Unlock
      </a>
    ),
  },
];

function showConfirm(customerid, unitid) {
  let dataValue = {
    customer_id: customerid,
    unit_id: unitid,
  };
  confirm({
    title: "Comfirm",
    icon: <ExclamationCircleOutlined />,
    content: "Are you sure to unlock?",
    onOk() {
      UnitsService.unlockUnit(dataValue)
        .then((data) => {
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => console.log("Oops errors!"));
        })
        .catch((err) => {
          console.log("Oops errors!");
        });
    },
    onCancel() {},
  });
}

export default TableUnit;

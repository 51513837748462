import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
  async login(email, password) {
    const response = await axios.post(API_URL + "/auth/login", {
      email,
      password,
    });
    if (response.data.access_token) {
      localStorage.setItem(
        process.env.REACT_APP_USER_KEY,
        JSON.stringify(response.data)
      );
    }
    return response.data;
  }

  logout() {
    localStorage.removeItem(process.env.REACT_APP_USER_KEY);
  }

  register(email, password) {
    return axios.post(API_URL + "/auth/register", {
      email,
      password,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem(process.env.REACT_APP_USER_KEY));
  }
}

export default new AuthService();

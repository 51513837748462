import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Modal, Tag } from "antd";
import UserService from "../services/user.service";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { confirm } = Modal;

function TableUnit(prop) {
  const { search, col, search2 } = prop;
  // state column
  const [columns, setColumns] = useState(columnsUsers);

  // state table
  const [users, setUsers] = useState([]);
  // const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [filterUser, setFilterUser] = useState([]);

  ///search
  const filterSearch = () => {
    let temp = [...users];

    let result = temp.filter((ele) => {
      const colValue = ele[`${col}`].toString().toLowerCase();
      const searchValue = search.toLowerCase();
      const searchValue2 = search2.toLowerCase();

      return (
        colValue.includes(searchValue) &&
        (searchValue2 !== "" ? (ele.company_id.toString().toLowerCase() === searchValue2) : "")
      );
    });

    setFilterUser([...result]);
  };

  // fetch data
  const fetch = async (params = { page: 1, pageSize: 10 }) => {
    try {
      setLoading(true);
      const ret = await UserService.getUsers();

      // check rem error
      if (ret.status !== 200) {
        throw { message: ret.data.message };
      }

      const data = ret.data;
      setLoading(false);
      setUsers(data);

      if (data.length > 0) {
        setColumns(columnsUsers);
      }
    } catch (error) {
      return;
    }
  };

  const conditionEffect = (e) => {
    if (search !== "") {
      return filterSearch();
    }

    return fetch();
  };

  const conditionEffect2 = (e) => {
    console.log()
    if (search2 !== "") {
      return filterSearch();
    }

    return fetch();
  };

  useEffect(() => {
    conditionEffect();
  }, [search]);

  useEffect(() => {
    conditionEffect2();
  }, [search2]);

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={search !== "" || search2 !== "" ? filterUser : users}
        // pagination={pagination}
        loading={loading}
        // onChange={handleTableChange}
        scroll={false}
        className="table-row-pointer"
      />
    </>
  );
}
// ••••••••••••••••••••
const columnsUsers = [
  {
    title: "First Name",
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    key: "last_name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Created Date",
    dataIndex: "created_date",
    key: "created_date",
    render: (text) => (
      <span>{moment(text).format("Do MMMM YYYY, h:mm:ss a")}</span>
    ),
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    align: "center",
    render: (text) => {
      if (text === "Deactive") {
        return (
          <Tag color={"red"} key={text}>
            {text.toUpperCase()}
          </Tag>
        );
      } else {
        return (
          <Tag color={"green"} key={text}>
            {text.toUpperCase()}
          </Tag>
        );
      }
    },
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <span>
        <Link to={"/users/edit/" + text.id} style={{ marginRight: 16 }}>
          <EditOutlined /> Edit
        </Link>
        <a onClick={() => showConfirm(text.id)}>
          <DeleteOutlined /> Delete
        </a>
      </span>
    ),
  },
];

function showConfirm(id) {
  let dataValue = {
    id: id,
  };
  confirm({
    title: "Comfirm",
    icon: <ExclamationCircleOutlined />,
    content: "Are you sure to delete user?",
    onOk() {
      UserService.deleteUser(dataValue)
        .then((data) => {
          return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          }).catch(() => console.log("Oops errors!"));
        })
        .catch((err) => {
          console.log("Oops errors!");
        });
    },
    onCancel() {},
  });
}

export default TableUnit;

import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Space,
  Tree,
  Typography,
  Select,
  Row,
  Col,
  Card,
} from "antd";
import React, { useEffect, useState } from "react";
import MasterDataService from "../services/company.service";
import MyHomeService from "../services/masterdata.service";

const { confirm } = Modal;
const { Paragraph } = Typography;
const { Option } = Select;

function TableBackEndMenu({ id }) {
  const [loading, setLoading] = useState(false);
  const [optionsServiceMenu, setOptionsServiceMenu] = useState([]);
  const [detail, setDetail] = useState({
    id: id,
    selectedRowKeys: [],
  });

  const mapModuleData = (data) => {
    return data.map((n) => {
      //console.log(n);
      return { ...n, title: n.name, key: n.id, children: n.sub_menu };
    });
  };

  const getBackEndMenuMenu = async () => {
    try {
      setLoading(true);
      const ret = await MyHomeService.findAllBackEndByCompanyId(id);
      // check rem error
      if (ret.status !== 200) {
        throw { message: ret.data.message };
      }

      const data = ret.data?.menu;
      setOptionsServiceMenu(mapModuleData(data));
      setDetail({
        ...detail,
        selectedRowKeys: ret.data?.map_menu,
      });
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    getBackEndMenuMenu();
  }, []);

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Card title={<span style={{ padding: "0 24px 0 24px" }}>เมนู</span>}>
            <Tree
              checkable
              treeData={optionsServiceMenu}
              checkedKeys={detail.selectedRowKeys}
              onCheck={(checkedKeys) => {
                setDetail({
                  ...detail,
                  selectedRowKeys: checkedKeys,
                });
                MyHomeService.editBackEndCompanyId(detail.id, {
                  map_menu: checkedKeys,
                })
                  .then((data) => {
                    //console.log(data.data);
                    getBackEndMenuMenu();
                    setLoading(true);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default TableBackEndMenu;

import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

class UnitsService {
  getUnits() {
    return axios({
      url: `${API_URL}/company/unit`,
      method:"POST",
      headers: authHeader(),
    });
  }

  lockUnit() {
    return axios.post(API_URL + "/company/unit/lock", { headers: authHeader() });
  }

  unlockUnit(params) {
    return axios({
      url: `${API_URL}/company/unit/unlock`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }
}

export default new UnitsService();

import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Card, Layout, notification, Select, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import TableServiceMenuLineOa from "../../components/Master/TableServiceMenuLineOa";
import TableBackendMenu from "../../components/Master/TableBackEndMenu";
import CompanyService from "../../services/company.service";
import itemRender from "../../utils/itemsRender";

const { Content, Header } = Layout;
const { Option } = Select;
function ListCompany() {
  var id = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );

  const [fields, setFields] = useState(null);
  const [fieldsMenu, setFieldsMenu] = useState(null);

  const openNotificationWithIcon = (type) => {
    if (type === "success") {
      notification[type]({
        message: "Success",
        description: "Change credential successfully.",
      });
    } else {
      notification[type]({
        message: "Error",
        description: "Something when wrong.",
      });
    }
  };
  const onFinish = (values) => {
    CompanyService.editCompany(values)
      .then((data) => {
        openNotificationWithIcon("success");
        //window.history.back();
      })
      .catch((err) => {
        openNotificationWithIcon("error");
        //console.log(err);
      });
  };

  function loadCompany() {
    CompanyService.getCompany()
      .then((data) => {
        setFields(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    //loadCompany();
  }, []);

  return (
    <>
      <Breadcrumb
        itemRender={itemRender}
        items={[
          {
            title: <HomeOutlined />,
            path: "/",
          },
          {
            title: "Master Data",
            path: "/",
          },
        ]}
        style={{ margin: "16px 0" }}
      ></Breadcrumb>
      <Card ghost="false" title="Master Data" subtitle="This is a master data">
        <Tabs
          defaultActiveKey="lineoa_1"
          size="large"
          type="card"
          items={[
            {
              key: "lineoa_1",
              label: "My Home Menu LINE OA",
              children: <TableServiceMenuLineOa fields={fields} />,
            },
            {
              key: "backend_1",
              label: "Menu BackEnd",
              children: <TableBackendMenu fields={fieldsMenu} />,
            },
          ]}
        ></Tabs>
      </Card>
    </>
  );
}

export default ListCompany;

import React, { useState, useEffect } from "react";
import { Breadcrumb, Card, Layout } from "antd";
import TableUnits from "../../components/TableUnits";
import { HomeOutlined, TableOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { SearchCustom } from "../../components/SearchCustom";
import itemRender from "../../utils/itemsRender";

const { Content, Header } = Layout;

function ListUnit() {
  const [search, setSearch] = useState("");
  const [colFilter, setColFilter] = useState("project_name");
  return (
    <>
      <Breadcrumb
        itemRender={itemRender}
        items={[
          {
            title: <HomeOutlined />,
            path: "/",
          },
          {
            title: (
              <>
                <TableOutlined /> <span>Units</span>
              </>
            ),
          },
        ]}
        style={{ margin: "16px 0" }}
      ></Breadcrumb>
      <Card ghost="false" title="Units" subtitle="This is Units">
        <SearchCustom
          setSearch={setSearch}
          setColFilter={setColFilter}
          addon={"units"}
        />
        <br />
        <br />

        <TableUnits search={search} col={colFilter} />
      </Card>
    </>
  );
}

export default ListUnit;

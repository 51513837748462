import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Layout,
  Form,
  Input,
  Button,
  Card,
  Row,
  notification,
  Tabs,
} from "antd";
import { HomeOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import CustomerService from "../../services/customer.service";
import itemRender from "../../utils/itemsRender";

const { Content } = Layout;

function DetailCustomer() {
  var id = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );

  const [fields, setFields] = useState(null);
  const [addressFields, setAddressFields] = useState(null);
  const openNotificationWithIcon = (type, module, msg) => {
    if (module === "info") {
      if (type === "success") {
        notification[type]({
          message: "Success",
          description: "Change Customer Info successfully.",
        });
      } else {
        notification[type]({
          message: "Error",
          description: msg.message,
        });
      }
    } else if (module === "address") {
      if (type === "success") {
        notification[type]({
          message: "Success",
          description: "Change Address successfully.",
        });
      } else {
        notification[type]({
          message: "Error",
          description: msg.message,
        });
      }
    } else {
      if (type === "success") {
        notification[type]({
          message: "Success",
          description: "Change Password successfully.",
        });
      } else {
        notification[type]({
          message: "Error",
          description:
            msg.message === "Request failed with status code 401"
              ? "Invalid Password!"
              : msg.message,
        });
      }
    }
  };
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    CustomerService.editCustomer(values)
      .then((data) => {
        openNotificationWithIcon("success", "info", "");
        //window.history.back();
      })
      .catch((err) => {
        openNotificationWithIcon("error", "info", err);
        //console.log(err);
      });
  };

  const onFinishAddress = (values) => {
    console.log("Received values of form: ", values);
    CustomerService.editCustomerAddress(values)
      .then((data) => {
        openNotificationWithIcon("success", "address", "");
        //window.history.back();
      })
      .catch((err) => {
        openNotificationWithIcon("error", "address", err);
        //console.log(err);
      });
  };

  const onFinishPassword = (values) => {
    console.log("Received values of form: ", values);
    CustomerService.editCustomerPassword(values)
      .then((data) => {
        openNotificationWithIcon("success", "password", "");
        //window.history.back();
      })
      .catch((err) => {
        openNotificationWithIcon("error", "password", err);
        //console.log(err);
      });
  };

  const EditCustomerForm = ({ fields }) => {
    const [form] = Form.useForm();

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 8,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 16,
        },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <Row justify="center" align="middle">
        <Card style={{ width: "80%" }}>
          <Form
            {...formItemLayout}
            form={form}
            name="edit"
            onFinish={onFinish}
            initialValues={fields}
            scrollToFirstError
          >
            <Form.Item
              name="Id"
              label="Id"
              rules={[
                {
                  required: true,
                  message: "Please input the id!",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please input the email!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please input the first name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please input the last name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="prefix_id"
              label="Prefix Id"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input the prefix!",
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="prefix"
              label="Prefix"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input the prefix!",
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="username"
              label="Username"
              rules={[
                {
                  required: true,
                  message: "Please input the username!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="mobile_no"
              label="Mobile No."
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input the mobile no!",
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="citizen_id"
              label="Citizen Id"
              rules={[
                {
                  required: true,
                  message: "Please input the citizen id!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="passport_no"
              label="Passport No."
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input the passport no!",
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="nationality"
              label="Nationality"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input the nationality!",
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="date_of_birth"
              label="Date Of Birth"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input the date of birth!",
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="customer_id_rem"
              label="Customer Id REM"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input the customer!",
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="customer_id_pm"
              label="Customer Id PM"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input the customer!",
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="customer_id_rental"
              label="Customer Id Rental"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input the customer!",
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="developer_id"
              label="Developer Id REM"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input the developer!",
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="developer_id_pm"
              label="Developer Id PM"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input the developer!",
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="developer_id_rental"
              label="Developer Id Rental"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please input the developer!",
            //   },
            // ]}
            >
              <Input />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    );
  };

  const EditAddressForm = ({ addressFields }) => {
    const [form] = Form.useForm();

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 8,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 16,
        },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <Row justify="center" align="middle">
        <Card style={{ width: "80%" }}>
          <Form
            {...formItemLayout}
            form={form}
            name="edit_address"
            onFinish={onFinishAddress}
            initialValues={addressFields}
            scrollToFirstError
          >
            <Form.Item
              name="type"
              label="Type"
              rules={[
                {
                  required: true,
                  message: "Please input the type!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: true,
                  message: "Please input the address!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="moo"
              label="Moo"
              rules={[
                {
                  required: true,
                  message: "Please input the moo!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="soi"
              label="Soi"
              rules={[
                {
                  required: true,
                  message: "Please input the soi!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="village"
              label="Village"
              rules={[
                {
                  required: true,
                  message: "Please input the village!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="road"
              label="Road"
              rules={[
                {
                  required: true,
                  message: "Please input the road!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="sub_district"
              label="SubDistrict"
              rules={[
                {
                  required: true,
                  message: "Please input the subDistrict!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="district"
              label="District"
              rules={[
                {
                  required: true,
                  message: "Please input the district!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="province"
              label="Province"
              rules={[
                {
                  required: true,
                  message: "Please input the province!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="zip_code"
              label="Zip Code"
              rules={[
                {
                  required: true,
                  message: "Please input the date of zip code!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="country"
              label="Country"
              rules={[
                {
                  required: true,
                  message: "Please input the country!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    );
  };

  const EditPasswordForm = () => {
    const [form] = Form.useForm();

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 8,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 16,
        },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <Row justify="center" align="middle">
        <Card style={{ width: "80%" }}>
          <Form
            {...formItemLayout}
            form={form}
            name="edit_password"
            onFinish={onFinishPassword}
            scrollToFirstError
          >
            <Form.Item
              name="password"
              label="Old Password"
              rules={[
                {
                  required: true,
                  message: "Please input the old password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="new_password"
              label="New Password"
              rules={[
                {
                  min: 8,
                  max: 8,
                  message: "Please input password should be 8 characters!",
                },
                {
                  required: true,
                  message: "Please input the new password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirm_new_password"
              label="Confirm New Password"
              dependencies={["new_password"]}
              hasFeedback
              rules={[
                {
                  min: 8,
                  max: 8,
                  message: "Please input password should be 8 characters!",
                },
                {
                  required: true,
                  message: "Please input confirm new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "The two passwords that you entered do not match!"
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item name="id" initialValue={id} label="Customer Id">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    );
  };

  function loadProfile() {
    CustomerService.getCustomerById(id)
      .then((data) => {
        setFields(data.data);
        CustomerService.getCustomerAddressById(id)
          .then((data_addr) => {
            //console.log(data_addr.data);
            setAddressFields(data_addr.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <>
      <Breadcrumb itemRender={itemRender} items={[
        {
          title: <HomeOutlined />,
          path: "/"
        },
        {
          title: <>
            <UsergroupAddOutlined />
            <span>Customer</span>
          </>,
          path: "/customer"
        },
        {
          title: "Details",
        },
      ]} style={{ margin: "16px 0" }}>
      </Breadcrumb>

      <Card
        ghost="false"
        title="Detail Customer"
        subtitle="Customer for customer portal."
      >
        <Tabs defaultActiveKey="1" size="large" type="card" items={[
          {
            key: "1",
            label: "Info",
            children: <EditCustomerForm fields={fields} />
          },
          {
            key: "2",
            label: "Address",
            children: <EditAddressForm addressFields={addressFields} />
          },
          {
            key: "3",
            label: "Password",
            children: <EditPasswordForm />
          }
        ]}>
        </Tabs>
      </Card>
    </>
  );
}

export default DetailCustomer;

import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

class MasterDataService {
  /*
   * Start LINE OA My Home Service Menu
   */
  getMyHomeServiceMenu() {
    return axios.get(API_URL + "/listmenu", {
      headers: authHeader(),
    });
  }

  getMyHomeServiceMenuById(id) {
    return axios.get(API_URL + "/listmenu/" + id, {
      headers: authHeader(),
    });
  }

  createMyHomeServiceMenu(params) {
    return axios({
      url: `${API_URL}/listmenu/create`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  editMyHomeServiceMenu(params) {
    return axios({
      url: `${API_URL}/listmenu/edit`,
      method: "PUT",
      data: params,
      headers: authHeader(),
    });
  }

  deleteMyHomeServiceMenu(params) {
    return axios({
      url: `${API_URL}/listmenu/delete`,
      method: "DELETE",
      data: params,
      headers: authHeader(),
    });
  }

  updateStatusMyHomeServiceMenu(params) {
    return axios({
      url: `${API_URL}/listmenu/updatestatus`,
      method: "PUT",
      data: params,
      headers: authHeader(),
    });
  }

  editMyHomeServiceMenuSeq(params) {
    return axios({
      url: `${API_URL}/listmenu/editSeq`,
      method: "PUT",
      data: params,
      headers: authHeader(),
    });
  }

  /*
   *End LINE OA My Home Service Menu
   */

   findAllMyHomeByCompanyId(params) {
    return axios({
      url: `${API_URL}/company/listmenu/${params}`,
      method: "GET",
      headers: authHeader(),
    });
  }

  findOneMyHomeByCompanyId(params) {
    return axios({
      url: `${API_URL}/company/listmenu/edit/${params}`,
      method: "GET",
      headers: authHeader(),
    });
  }

  createMyHomeByCompanyId(params) {
    return axios({
      url: `${API_URL}/company/master/linemyhomeservice/create`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  editMyHomeByCompanyId(id,params) {
    return axios({
      url: `${API_URL}/company/listmenu/edit/${id}`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  removeMyHomeByCompanyId(params) {
    return axios({
      url: `${API_URL}/company/master/linemyhomeservice/delete`,
      method: "DELETE",
      data: params,
      headers: authHeader(),
    });
  }

  multiDeleteMyHomeByCompanyId(params) {
    return axios({
      url: `${API_URL}/company/master/linemyhomeservice/multidelete`,
      method: "DELETE",
      data: params,
      headers: authHeader(),
    });
  }

  //BackEnd Menu
  getBackEndMenu() {
    return axios.get(API_URL + "/backendmenu", {
      headers: authHeader(),
    });
  }

  getBackEndMenuById(id) {
    return axios.get(API_URL + "/backendmenu/" + id, {
      headers: authHeader(),
    });
  }

  createBackEndeMenu(params) {
    return axios({
      url: `${API_URL}/backendmenu/create`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  editBackEndMenu(params) {
    return axios({
      url: `${API_URL}/backendmenu/edit`,
      method: "PUT",
      data: params,
      headers: authHeader(),
    });
  }

  deleteBackEndMenu(params) {
    return axios({
      url: `${API_URL}/backendmenu/delete`,
      method: "DELETE",
      data: params,
      headers: authHeader(),
    });
  }

  updateStatusBackEndMenu(params) {
    return axios({
      url: `${API_URL}/backendmenu/updatestatus`,
      method: "PUT",
      data: params,
      headers: authHeader(),
    });
  }

  editBackEndMenuSeq(params) {
    return axios({
      url: `${API_URL}/backendmenu/editSeq`,
      method: "PUT",
      data: params,
      headers: authHeader(),
    });
  }

   findAllBackEndByCompanyId(params) {
    return axios({
      url: `${API_URL}/company/backendmenu/${params}`,
      method: "GET",
      headers: authHeader(),
    });
  }

  findOneBackEndByCompanyId(params) {
    return axios({
      url: `${API_URL}/company/backendmenu/edit/${params}`,
      method: "GET",
      headers: authHeader(),
    });
  }

  // createBackEndByCompanyId(params) {
  //   return axios({
  //     url: `${API_URL}/company/master/linemyhomeservice/create`,
  //     method: "POST",
  //     data: params,
  //     headers: authHeader(),
  //   });
  // }

  editBackEndCompanyId(id,params) {
    return axios({
      url: `${API_URL}/company/backendmenu/edit/${id}`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }
}

export default new MasterDataService();

import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Layout,
  Form,
  Input,
  Button,
  Card,
  Row,
  notification,
} from "antd";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import UsersService from "../../services/user.service";
import { Link } from "react-router-dom";
import itemRender from "../../utils/itemsRender";

const { Content, Header } = Layout;

function ListCredentials() {
  var id = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );

  const [fields, setFields] = useState(null);
  const openNotificationWithIcon = (type) => {
    if (type === "success") {
      notification[type]({
        message: "Success",
        description: "Change user successfully.",
      });
    } else {
      notification[type]({
        message: "Error",
        description: "Something when wrong.",
      });
    }
  };
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    UsersService.editUser(values)
      .then((data) => {
        openNotificationWithIcon("success");
        window.history.back();
      })
      .catch((err) => {
        openNotificationWithIcon("error");
        //console.log(err);
      });
  };
  const EditUserForm = ({ fields }) => {
    const [form] = Form.useForm();

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 8,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 16,
        },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <Row justify="center" align="middle">
        <Card style={{ width: "50%" }}>
          <Form
            {...formItemLayout}
            form={form}
            name="edit"
            onFinish={onFinish}
            initialValues={fields}
            scrollToFirstError
          >
            <Form.Item
              name="id"
              label="Id"
              rules={[
                {
                  required: true,
                  message: "Please input the id!",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please input the email!",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="username"
              label="Username"
              rules={[
                {
                  required: true,
                  message: "Please input the username!",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="first_name"
              label="Frist Name"
              rules={[
                {
                  required: true,
                  message: "Please input the first name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please input the last name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="new_password"
              label="New Password"
              rules={[
                {
                  required: false,
                  // message: "Please input the name of company token!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    );
  };

  function loadProfile() {
    UsersService.getUserById(id)
      .then((data) => {
        setFields(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <>
      <Breadcrumb
        itemRender={itemRender}
        items={[
          {
            title: <HomeOutlined />,
            path: "/",
          },
          {
            title: (
              <>
                <UserOutlined /> <span>User</span>
              </>
            ),
            path: "/users",
          },
          {
            title: "Edit",
          },
        ]}
        style={{ margin: "16px 0" }}
      ></Breadcrumb>
      <Card ghost="false" title="Edit User" subtitle="This is a user">
        <EditUserForm fields={fields} />
      </Card>
    </>
  );
}

export default ListCredentials;

import React, { useState, useEffect } from 'react'
import {
  Input,
  Select,
} from "antd";

const { Search } = Input;
const { Option } = Select;

export const SearchCustom = ({ setSearch, setColFilter, addon, ...props }) => {


  const onSearch = e => {
    setSearch(e.target.value)
  };

  const filterCompany = (
    <Select defaultValue="name" className="select-before" onChange={(e) => setColFilter(e)}>
      <Option value="name">Name</Option>
      <Option value="url">URL</Option>
      <Option value="alias">Alias</Option>
    </Select>
  );


  const filterCustomer = (
    <Select defaultValue="FirstName" className="select-before" onChange={(e) => setColFilter(e)}>
      <Option value="FirstName">First Name</Option>
      <Option value="LastName">Last Name</Option>
      <Option value="Email">Email</Option>
      <Option value="Username">Username</Option>

    </Select>
  )

  const filterUser = (
    <Select defaultValue="username" className="select-before" onChange={(e) => setColFilter(e)}>
      <Option value="first_name">First Name</Option>
      <Option value="last_name">Last Name</Option>
      <Option value="email">Email</Option>
      <Option value="username">Username</Option>
    </Select>
  )

  const filterUnit = (
    <Select defaultValue="project_name" className="select-before" onChange={(e) => setColFilter(e)}>
      <Option value="project_name">Project</Option>
      <Option value="unit_number">Unit Number	</Option>
    </Select>
  )

  const filterCredentails = (
    <Select defaultValue="Name" className="select-before" onChange={(e) => setColFilter(e)}>
      <Option value="Name">Name</Option>
      <Option value="Origins">Origins</Option>
    </Select>
  )


  const filterRichMenu = (
    <Select defaultValue="Name" className="select-before" onChange={(e) => setColFilter(e)}>
      <Option value="name">Name</Option>
      <Option value="richMenuId">RichMenuId</Option>
    </Select>
  )

  const filterLiffApp = (
    <Select defaultValue="Name" className="select-before" onChange={(e) => setColFilter(e)}>
      <Option value="name">LIFF NAME</Option>
      <Option value="richMenuId">LIFF ID</Option>
    </Select>
  )


  const filterSelect = {
    "company": filterCompany,
    "customer": filterCustomer,
    "credentials": filterCredentails,
    "user": filterUser,
    "units": filterUnit,
    "richmenu": filterRichMenu,
    "liffapp": filterLiffApp
  }




  return (

    <Search placeholder="search" addonBefore={filterSelect[addon]} onChange={(e) => onSearch(e)} style={{ width: "80%" }} />
  )


}


import React from "react";
import { Row } from "antd";
//import "antd/dist/antd.css";

function LoginLayout({ children }) {

  return (
    <Row
      justify="center"
      align="middle"
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        background: "#f0f2f5",
        backgroundImage:
          "url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center 110px",
        backgroundSize: "100%",
      }}
    >
      <div>
        <h1 style={{ textAlign: "center", marginBottom: "30px" }}>
          Admin Portal
        </h1>
        {children}
        <p style={{ marginTop: "30px", textAlign: "center" }}>
          Admin Portal ©2024
        </p>
      </div>
    </Row>
  );
}

export default LoginLayout;

import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

class TermsService {
  getTerms() {
    return axios.get(API_URL + "/terms", {
      headers: authHeader(),
    });
  }

  getTermsById(id) {
    return axios.get(API_URL + "/terms/" + id, {
      headers: authHeader(),
    });
  }

  createTerms(params) {
    return axios({
      url: `${API_URL}/terms/add`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  editTerms(params) {
    return axios({
      url: `${API_URL}/terms/update`,
      method: "PUT",
      data: params,
      headers: authHeader(),
    });
  }

  deleteTerms(params) {
    return axios({
      url: `${API_URL}/terms/delete`,
      method: "DELETE",
      data: params,
      headers: authHeader(),
    });
  }

  getTermsByCompanyId(params) {
    return axios({
      url: `${API_URL}/terms/company/${params}`,
      method: "GET",
      headers: authHeader(),
    });
  }
}

export default new TermsService();

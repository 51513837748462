import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Modal } from "antd";
import CustomerService from "../services/customer.service";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { confirm } = Modal;

function TableUnit(prop) {
  // state column
  const [columns, setColumns] = useState(columnsUsers);
  const { search, col } = prop

  // state table
  const [users, setUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState([])
  // const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);


  ///search
  const filterSearch = () => {

    let temp = [...users]
    let result = temp.filter(ele => ele[`${col}`] !== null ? ele[`${col}`].toLowerCase().includes(search.toLowerCase()) : null)

    setFilterUsers([...result])


  }


  // fetch data
  const fetch = async (params = { page: 1, pageSize: 10 }) => {
    try {
      setLoading(true);
      const ret = await CustomerService.getCustomer();

      // check rem error
      if (ret.status !== 200) {
        throw { message: ret.data.message };
      }

      const data = ret.data;
      setLoading(false);
      setUsers(data);

      if (data.length > 0) {
        setColumns(columnsUsers);
      }
    } catch (error) {
      return;
    }
  };

  const conditionEffect = (e) => {

    if (search !== "") {
      return filterSearch()
    }
    return fetch()

  }


  useEffect(() => {
    conditionEffect()
  }, [search]);

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record.Id + record.first_name}
        dataSource={search !== "" ? filterUsers : users}

        // pagination={pagination}
        loading={loading}
        // onChange={handleTableChange}
        scroll={false}
        className="table-row-pointer"
      />
    </>
  );
}
// ••••••••••••••••••••
const columnsUsers = [
  {
    title: "Id",
    dataIndex: "id",
    key: "Id",
  },
  {
    title: "First Name",
    dataIndex: "first_name",
    key: "FirstName",
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    key: "LastName",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "Email",
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "Username",
  },
  {
    title: "Created Date",
    dataIndex: "create_date",
    key: "CreateDate",
    render: (text) => (
      <span>{moment(text).format("DD MMMM YYYY, hh:mm:ss a")}</span>
    ),
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <span>
        <Link to={"/customer/" + text.id} style={{ marginRight: 16 }}>
          <EditOutlined /> Detail
        </Link>
      </span>
    ),
  },
];

export default TableUnit;

import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Layout,
  Form,
  Input,
  Button,
  Card,
  notification,
  Select,
  Divider,
  Typography,
} from "antd";
import {
  HomeOutlined,
  SafetyCertificateOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import CredentialsService from "../../services/credentials.service";
import hljs from "highlight.js/lib/core";
import xml from "highlight.js/lib/languages/xml";
import javascript from "highlight.js/lib/languages/javascript";
import json from "highlight.js/lib/languages/json";
import "highlight.js/styles/vs.css";
import UserService from "../../services/user.service";
import _ from "lodash";
import itemRender from "../../utils/itemsRender";
import { Buffer } from "buffer";
import { CopyToClipboard } from "react-copy-to-clipboard";

const { TextArea } = Input;
const { Paragraph } = Typography;

hljs.registerLanguage("xml", xml);
hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("json", json);

const { Content } = Layout;
function ListCredentials() {
  var tokenid = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );

  const [fields, setFields] = useState(null);
  const [copyCode, setCopyCode] = useState("");
  const [copyCodeWindow, setCopyCodeWindow] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [users, setUsers] = useState([]);
  const [userSelected, setUserSelected] = useState("");
  const [usernameSelected, setUsernameSelected] = useState("");

  // fetch data
  const fetch = async (company_id) => {
    try {
      const ret = await UserService.getUsers();

      // check rem error
      if (ret.status !== 200) {
        throw { message: ret.data.message };
      }

      const data = _.filter(ret.data, ["company_id", company_id]);
      let data_user = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        data_user.push({
          value: element.username ? element.username : element.email,
          label:
            "{ " +
            (element.username ? element.username : element.email + " (no sync) ") +
            " } " +
            element.first_name +
            " " +
            element.last_name,
        });
      }
      setUsers(data_user);
      setUserSelected(
        Buffer.from(
          `{"username":"${data_user[0].value}","token":"${tokenid}"}`
        ).toString("base64")
      );
      setUsernameSelected(data_user[0].value);
    } catch (error) {
      return;
    }
  };

  const openNotificationWithIcon = (type) => {
    if (type === "success") {
      notification[type]({
        message: "Success",
        description: "Change credential successfully.",
      });
    } else {
      notification[type]({
        message: "Error",
        description: "Something when wrong.",
      });
    }
  };
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    CredentialsService.editCredentials(values)
      .then((data) => {
        openNotificationWithIcon("success");
        //window.history.back();
      })
      .catch((err) => {
        openNotificationWithIcon("error");
        //console.log(err);
      });
  };
  const EditCredentialForm = ({ fields }) => {
    const [form] = Form.useForm();
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 8,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 16,
        },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    const onChange = (value) => {
      console.log(`selected ${value}`);
      setUsernameSelected(value);
      setUserSelected(
        Buffer.from(`{"username":"${value}","token":"${tokenid}"}`).toString(
          "base64"
        )
      );
    };
    const onSearch = (value) => {
      console.log("search:", value);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    return (
      <Form
        {...formItemLayout}
        form={form}
        name="edit"
        onFinish={onFinish}
        initialValues={fields}
        scrollToFirstError
      >
        <Form.Item name="token_id" label="TokenId">
          <Paragraph copyable>{fields?.token_id}</Paragraph>
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input the name of company token!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="origins"
          label="Origins"
          rules={[
            {
              required: true,
              message: "Please input the origins of company token!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="username"
          label="User Name"
          rules={[
            {
              required: true,
              message: "Please input the origins of company token!",
            },
          ]}
          initialValue={usernameSelected}
        >
          <Select
            //defaultActiveFirstOption={true}
            placeholder="Select a user"
            onChange={onChange}
            options={users}
          />
        </Form.Item>
        <Form.Item
          name="type"
          label="Server Type"
          rules={[
            {
              required: true,
              message: "Please input the origins of company token!",
            },
          ]}
          style={{ display: "none" }}
        >
          <Select>
            {/* <Select.Option value="dev">Dev</Select.Option> */}
            <Select.Option value="uat">Uat</Select.Option>
            <Select.Option value="prod">Prod</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="merchant_id"
          label="Merchant Id"
          style={{ display: "none" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="terminal_id"
          label="Terminal Id"
          style={{ display: "none" }}
        >
          <Input />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
        <Divider></Divider>
        <div>
          <Card title="Link">
            <Form.Item label="Link Landing">
              <CopyToClipboard
                text={`${process.env.REACT_APP_WEB_URL}/landing?userToken=${userSelected}`}
                button-title="Copy"
                style={{
                  position: "absolute",
                  right: 0,
                  zIndex: 999,
                  backgroundColor: "#1677ff",
                  color: "white",
                  width: "40px",
                  height: "100%",
                  border: "0px",
                }}
                onCopy={copyToClipboardLink}
              >
                <Button>
                  <CopyOutlined />
                </Button>
              </CopyToClipboard>
              <TextArea
                className="linklogin"
                rows={4}
                style={{ border: "#ccc 1px solid", padding: "5px" }}
                value={`${process.env.REACT_APP_WEB_URL}/landing?userToken=${userSelected}`}
              />
            </Form.Item>

            <Form.Item label="Oauth2">
              <CopyToClipboard
                text={`https://customer.onlinesecuregateway.com/oauth2?embedded=true&redirect_uri=${redirectUrl}&client_id=${tokenid}`}
                button-title="Copy"
                style={{
                  position: "absolute",
                  right: 0,
                  zIndex: 999,
                  backgroundColor: "#1677ff",
                  color: "white",
                  width: "40px",
                  height: "100%",
                  border: "0px",
                }}
                onCopy={copyToClipboardOauth2}
              >
                <Button>
                  <CopyOutlined />
                </Button>
              </CopyToClipboard>

              <TextArea
                className="oauth2"
                rows={4}
                value={`https://customer.onlinesecuregateway.com/oauth2?embedded=true&redirect_uri=${redirectUrl}&client_id=${tokenid}`}
                style={{ border: "#ccc 1px solid", padding: "5px" }}
              />
            </Form.Item>
          </Card>
          <br />
          <Card title="HTML">
            <Form.Item label="Code">
              <CopyToClipboard
                text={
                  '<iframe \n height="670" \n width="100%" \n style={{ border: 0 }} \n src="https://customer.onlinesecuregateway.com/oauth2?embedded=true&redirect_uri=' +
                  redirectUrl +
                  "&client_id=" +
                  tokenid +
                  '"> \n</iframe> \n\n'
                }
                button-title={"Copy"}
                style={{
                  position: "absolute",
                  right: 0,
                  zIndex: 999,
                  backgroundColor: "#1677ff",
                  color: "white",
                  width: "40px",
                  height: "100%",
                  border: "0px",
                }}
                onCopy={copyToClipboard}
              >
                <Button>
                  <CopyOutlined />
                </Button>
              </CopyToClipboard>
              <TextArea
                className="xml"
                style={{ border: "#ccc 1px solid", padding: "5px" }}
                rows={7}
                value={
                  '<iframe \n height="670" \n width="100%" \n style={{ border: 0 }} \n src="https://customer.onlinesecuregateway.com/oauth2?embedded=true&redirect_uri=' +
                  redirectUrl +
                  "&client_id=" +
                  tokenid +
                  '"> \n</iframe> \n\n'
                }
              ></TextArea>
            </Form.Item>
          </Card>
          <br />
          <Card title="JavaScript">
            <Form.Item label="Code">
              <CopyToClipboard
                text={
                  "var windowObjectReference; \n" +
                  'var windowFeatures = "width=480,height=670"; \n' +
                  "function openRequestedPopup() { \n" +
                  "windowObjectReference = window.open( \n" +
                  '"https://customer.onlinesecuregateway.com/oauth2?embedded=true&redirect_uri=' +
                  redirectUrl +
                  '&client_id="' +
                  tokenid +
                  ", \n" +
                  '"CNN_WindowName", \n' +
                  "windowFeatures); \n" +
                  "}"
                }
                button-title="Copy"
                style={{
                  position: "absolute",
                  right: 0,
                  zIndex: 999,
                  backgroundColor: "#1677ff",
                  color: "white",
                  width: "40px",
                  height: "100%",
                  border: "0px",
                }}
                onCopy={copyToClipboardWindow}
              >
                <Button>
                  <CopyOutlined />
                </Button>
              </CopyToClipboard>
              <TextArea
                className="javascript"
                style={{ border: "#ccc 1px solid", padding: "5px" }}
                rows={10}
                value={
                  "var windowObjectReference; \n" +
                  'var windowFeatures = "width=480,height=670"; \n' +
                  "function openRequestedPopup() { \n" +
                  "windowObjectReference = window.open( \n" +
                  '"https://customer.onlinesecuregateway.com/oauth2?embedded=true&redirect_uri=' +
                  redirectUrl +
                  '&client_id="' +
                  tokenid +
                  ", \n" +
                  '"CNN_WindowName", \n' +
                  "windowFeatures); \n" +
                  "}"
                }
              />
            </Form.Item>
          </Card>
        </div>

        {/* <TabPane tab="Booking" key="2">
                <Form.Item label="Code">
                  <Input
                    addonBefore="POST"
                    defaultValue="{{URL}}/company/verify"
                  />
                  <br />
                  <br />
                  <Clipboard
                    data-clipboard-text={"test"}
                    button-title="Copy"
                    className="test"
                    style={{ position: "absolute", right: 0 }}
                    onClick={copyToClipboardBooking}
                  >
                    <CopyOutlined />
                  </Clipboard>
                  <pre style={{ border: "#ccc 1px solid", padding: "5px" }}>
                    <code
                      className="json"
                      style={{ backgroundColor: "#ffffff" }}
                    >
                      {"{\n" +
                        "  token: { " +
                        tokenid +
                        " } \n" +
                        "  project: {}\n" +
                        "  model: {}\n" +
                        "  tower: {}\n" +
                        "  floor: {}\n" +
                        "}"}
                    </code>
                  </pre>
                </Form.Item>
              </TabPane> */}
      </Form>
    );
  };

  async function loadProfile() {
    await CredentialsService.getCredentialsById(tokenid)
      .then((data) => {
        setFields(data.data);
        fetch(data.data.company_id);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    loadProfile();
  }, []);

  useEffect(() => {
    hljs.highlightAll();
  }, []);

  return (
    <>
      <Breadcrumb
        itemRender={itemRender}
        items={[
          {
            title: <HomeOutlined />,
            path: "/",
          },
          {
            title: (
              <>
                <SafetyCertificateOutlined /> Credentials
              </>
            ),
            path: "/credentials",
          },
          {
            title: "Edit",
          },
        ]}
        style={{ margin: "16px 0" }}
      ></Breadcrumb>
      <Card ghost="false" title="Edit Credentials">
        <EditCredentialForm fields={fields} />
      </Card>
    </>
  );
}

function copyToClipboard(e) {
  //e.target.focus();
  openNotificationCopy("success", "xml");
}
function copyToClipboardWindow(e) {
  //e.target.focus();
  openNotificationCopy("success", "javascript");
}
function copyToClipboardLink(e) {
  //e.target.focus();
  openNotificationCopy("success", "linklogin");
}
function copyToClipboardOauth2(e) {
  //e.target.focus();
  openNotificationCopy("success", "oauth2");
}
function copyToClipboardBooking(e) {
  //e.target.focus();
  openNotificationCopy("success", "json");
}

function openNotificationCopy(type, cname) {
  document.execCommand("copy");
  if (cname === "xml") {
    document.getElementsByClassName(cname)[0].style.backgroundColor = "#ffd583";
    document.getElementsByClassName(cname)[0].style.color = "#000000";
    document.getElementsByClassName("javascript")[0].style.backgroundColor =
      "#ffffff";
    document.getElementsByClassName("javascript")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
    document.getElementsByClassName("linklogin")[0].style.backgroundColor =
      "#ffffff";
    document.getElementsByClassName("linklogin")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
    document.getElementsByClassName("oauth2")[0].style.backgroundColor =
      "#ffffff";
    document.getElementsByClassName("oauth2")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
  } else if (cname === "javascript") {
    document.getElementsByClassName(cname)[0].style.backgroundColor = "#ffd583";
    document.getElementsByClassName(cname)[0].style.color = "#000000";
    document.getElementsByClassName("xml")[0].style.backgroundColor = "#ffffff";
    document.getElementsByClassName("xml")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
    document.getElementsByClassName("linklogin")[0].style.backgroundColor =
      "#ffffff";
    document.getElementsByClassName("linklogin")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
    document.getElementsByClassName("oauth2")[0].style.backgroundColor =
      "#ffffff";
    document.getElementsByClassName("oauth2")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
  } else if (cname === "linklogin") {
    document.getElementsByClassName(cname)[0].style.backgroundColor = "#ffd583";
    document.getElementsByClassName(cname)[0].style.color = "#000000";
    document.getElementsByClassName("xml")[0].style.backgroundColor = "#ffffff";
    document.getElementsByClassName("xml")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
    document.getElementsByClassName("javascript")[0].style.backgroundColor =
      "#ffffff";
    document.getElementsByClassName("javascript")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
    document.getElementsByClassName("oauth2")[0].style.backgroundColor =
      "#ffffff";
    document.getElementsByClassName("oauth2")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
  } else if (cname === "oauth2") {
    document.getElementsByClassName(cname)[0].style.backgroundColor = "#ffd583";
    document.getElementsByClassName(cname)[0].style.color = "#000000";
    document.getElementsByClassName("xml")[0].style.backgroundColor = "#ffffff";
    document.getElementsByClassName("xml")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
    document.getElementsByClassName("javascript")[0].style.backgroundColor =
      "#ffffff";
    document.getElementsByClassName("javascript")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
    document.getElementsByClassName("linklogin")[0].style.backgroundColor =
      "#ffffff";
    document.getElementsByClassName("linklogin")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
  } else if (cname === "json") {
    document.getElementsByClassName(cname)[0].style.backgroundColor = "#ffd583";
    document.getElementsByClassName(cname)[0].style.color = "#000000";
    document.getElementsByClassName("javascript")[0].style.backgroundColor =
      "#ffffff";
    document.getElementsByClassName("javascript")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
    document.getElementsByClassName("xml")[0].style.backgroundColor = "#ffffff";
    document.getElementsByClassName("xml")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
    document.getElementsByClassName("linklogin")[0].style.backgroundColor =
      "#ffffff";
    document.getElementsByClassName("linklogin")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
    document.getElementsByClassName("oauth2")[0].style.backgroundColor =
      "#ffffff";
    document.getElementsByClassName("oauth2")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
  } else {
    document.getElementsByTagName("code")[0].style.backgroundColor = "#ffffff";
    document.getElementsByTagName("code")[0].style.color =
      "rgba(0, 0, 0, 0.65)";
  }
  notification[type]({
    message: "Success",
    description: "Copied.",
  });
}
export default ListCredentials;

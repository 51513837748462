import React, { useState, useEffect } from "react";
import RichMenuService from "../../services/richmenu.service";
import {
  Upload,
  Popconfirm,
  Layout,
  Row,
  Form,
  Input,
  Button,
  Modal,
  Breadcrumb,
  Select,
  message,
  Card,
} from "antd";
import { Link } from "react-router-dom";
import {
  JsonView,
  allExpanded,
  darkStyles,
  defaultStyles,
} from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

import {
  HomeOutlined,
  LayoutOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CheckCircleTwoTone,
  UploadOutlined,
} from "@ant-design/icons";

import TableCustom from "../../components/TableCustom";
import itemRender from "../../utils/itemsRender";
const { Content, Header } = Layout;
const { TextArea } = Input;
const { Option } = Select;
const { confirm } = Modal;

function RichMenu() {
  const [richMenuList, setRichMenuList] = useState({ status: "", data: [] });
  const [richMenuDefault, setDefault] = useState("");
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [form] = Form.useForm();
  const [editInitVal, setEditInitVal] = useState({});
  const [addChannel, setAddChannel] = useState(false);
  const [options, setOptions] = useState({ status: "", data: [] });
  const [selectToken, setSelectToken] = useState();
  const [fileImg, setFileImg] = useState(null);
  const [uploading, setUpload] = useState(false);
  // config for TableCustom
  let configTable = {
    columns: [
      {
        title: "Default",
        dataIndex: "defaultID",
        key: "default",
        render: (text, record) => {
          if (record.richMenuId === richMenuDefault) {
            return (
              <Row justify="center">
                <CheckCircleTwoTone twoToneColor="#52c41a" />{" "}
              </Row>
            );
          }
        },
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "RichMenuId",
        dataIndex: "richMenuId",
        key: "richMenuId",
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <span>
            <Row>
              <a onClick={() => setInitEdit(true, record)}>
                <EditOutlined /> View
              </a>
              <Popconfirm
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => deleteRichMenu(text.richMenuId)}
              >
                <a>
                  <DeleteOutlined /> Delete
                </a>
              </Popconfirm>
            </Row>
          </span>
        ),
      },
    ],
    actions: "",
    data: richMenuList.data,
    addon: "richmenu",
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  /* eslint-disable no-template-curly-in-string */

  // config for TableCustom
  const configButton = {
    visible: true,
    buttonTitle: "Create RichMenu",
  };

  const configModal = {
    modalTitle: "Create RichMenu",
    body: (
      <div>
        <Form.Item name="json" label="Json">
          <TextArea rows={20} />
        </Form.Item>

        <Form.Item>
          <Row justify="end">
            <Button htmlType="button" onClick={() => setModalAdd(false)}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Row>
        </Form.Item>
      </div>
    ),
  };

  const uploadImg = {
    multiple: false,
    onRemove: () => {
      setFileImg(null);
    },

    beforeUpload: (file) => {
      setFileImg(file);
      return false;
    },
    fileImg,
  };

  const handleUpload = async (e) => {
    let id = e.richMenuId || null;

    setUpload(true);

    try {
      await RichMenuService.uploadImgRichMenu({
        richMenuId: id,
        access_token: selectToken,
        form: fileImg,
      });
      setUpload(false);
      setFileImg(null);

      message.success("upload successfully.");
    } catch (error) {
      setUpload(false);
      setFileImg(null);

      message.error(`This is an error message : ${error} `, 5);
    }
  };

  const configModalEdit = {
    modalTitle: "View RichMenu",
    body: (
      <div>
        <Form.Item name="name" label="Name">
          <Input readOnly />
        </Form.Item>
        <Form.Item name="richMenuId" label="RichMenuId">
          <Input readOnly />
        </Form.Item>

        <Popconfirm
          title="Are you sure？"
          okText="Yes"
          cancelText="No"
          onConfirm={() => setDefaultRichMenu(editInitVal)}
        >
          <Button type="primary">SET THIS DEFAULT RICH MENU</Button>
        </Popconfirm>

        <Upload {...uploadImg} showUploadList={false}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>

        <Button
          type="primary"
          onClick={() => handleUpload(editInitVal)}
          disabled={fileImg === null}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? "Uploading" : "Start Upload"}
        </Button>

        <JsonView
          name={false}
          data={editInitVal}
          shouldExpandNode={allExpanded}
          style={darkStyles}
        />

        <Row justify="center">
          <a htmltype="close" onClick={() => cancelEdit(false)}>
            Close
          </a>
        </Row>
      </div>
    ),
  };

  const closeModal = () => {
    form.resetFields(); /// reset fields after modal close
    setModalAdd(false);
  };

  const formFinish = (val) => {
    createRichMenu(val);
    closeModal();
  };

  const formChannelFinish = async (val) => {
    try {
      await RichMenuService.createChannelAccess(val);
      setAddChannel(false);
      optionChannelAccess();
      message.success("This is a success message");
    } catch (error) {
      console.error(error);
      message.error(`This is an error message : ${error} `, 5);
    }
  };

  const requestRichMenuDefault = async (token) => {
    try {
      let richMenuDefault = await RichMenuService.getRichMenuDefault({
        access_token: token,
      });
      setDefault(richMenuDefault.data.richMenuId);
    } catch (error) {
      console.error(error);
      message.error(
        `This is an error message : ${error} maybe should upload richmenu image before.`,
        5
      );
    }
  };

  const requestRichMenuList = async (token) => {
    let data = await RichMenuService.getRichMenuList({ access_token: token });
    setRichMenuList({ status: 200, data: data.data.richmenus });
    return { data: data.data.richmenus };
  };

  const setDefaultRichMenu = async (e) => {
    let id = e.richMenuId || null;

    try {
      await RichMenuService.setRichMenuDefault({
        richMenuId: id,
        access_token: selectToken,
      });
      message.success(`setDefault success`);
    } catch (error) {
      message.error(
        `This is an error message : ${error} maybe should upload richmenu image before.`,
        5
      );
    }

    await requestRichMenuList(selectToken);
    await requestRichMenuDefault(selectToken);
  };

  const optionChannelAccess = async () => {
    try {
      let query = await RichMenuService.findAllChannelAccess();
      console.log(query.data);
      setOptions({ status: query.status, data: query.data.data });
    } catch (error) {
      console.error(error);
      message.error(`This is an error message : ${error} `, 5);
    }
  };

  const createRichMenu = async (e) => {
    try {
      await RichMenuService.createRichMenu({
        json: e.json,
        access_token: selectToken,
      });
      await requestRichMenuList(selectToken);
      message.success("This is a success message");
    } catch (error) {
      console.error(error);
      message.error(`This is an error message : ${error} `, 5);
    }
    await requestRichMenuDefault(selectToken);
  };

  const deleteRichMenu = async (e) => {
    try {
      await RichMenuService.deleteRichMenu({
        richId: e,
        access_token: selectToken,
      });
      await requestRichMenuList(selectToken);
      message.success("This is a success message");
    } catch (error) {
      console.error(error);
      message.error(`This is an error message : ${error} `, 5);
    }
  };

  const deleteChannelAccess = async () => {
    try {
      await RichMenuService.deleteChannelAccess({ access_token: selectToken });
      setSelectToken("");
      await optionChannelAccess();
      message.success("This is a success message");
    } catch (error) {
      console.error(error);
      message.error(`This is an error message : ${error} `, 5);
    }
  };

  const showDelConfirm = (e) => {
    confirm({
      title: "Do you want to delete these channel access?",
      icon: <ExclamationCircleOutlined />,
      content: "When clicked the OK button, channel access will be remove",
      async onOk() {
        deleteChannelAccess();
      },
      onCancel() {},
    });
  };

  const setInitEdit = async (e, record) => {
    setEditInitVal(record);
    setFileImg(null);
    setModalEdit(e);
  };

  const cancelEdit = (e) => {
    setModalEdit(e);
    setFileImg(null);
  };

  const onSelectToken = async (e) => {
    setSelectToken(e);
    let result = await requestRichMenuList(e);
    if (result.data.length > 0) {
      requestRichMenuDefault(e);
    }
  };

  const conditionEffect = async () => {
    await optionChannelAccess();
  };

  useEffect(() => {
    //setDefault values when Edit Modal show up
    //form.setFieldsValue(editInitVal);
    conditionEffect();
  }, [richMenuList, editInitVal]);

  return (
    <>
      <Breadcrumb
        itemRender={itemRender}
        items={[
          {
            title: <HomeOutlined />,
            path: "/",
          },
          {
            title: (
              <>
                <LayoutOutlined /> <span>RichMenu</span>
              </>
            ),
            path: "/",
          },
        ]}
        style={{ margin: "16px 0" }}
      ></Breadcrumb>
      <Modal
        getContainer={false}
        title={
          modalAdd === true
            ? configModal.modalTitle
            : configModalEdit.modalTitle
        }
        centered
        open={modalAdd || modalEdit}
        onCancel={() =>
          modalAdd === true ? setModalAdd(false) : cancelEdit(false)
        }
        width={1000}
        footer={null}
      >
        <Form
          form={form}
          {...layout}
          name="nest-messages"
          onFinish={(val) => formFinish(val)}
        >
          {modalAdd === true ? configModal.body : configModalEdit.body}
        </Form>
      </Modal>

      <Modal
        getContainer={false}
        title="Add Channel"
        centered
        open={addChannel}
        onCancel={() => setAddChannel(false)}
        footer={null}
      >
        <Form
          name="channel-form"
          form={form}
          onFinish={(val) => formChannelFinish(val)}
        >
          <Form.Item name="channel_name" label="Channel Name">
            <Input></Input>
          </Form.Item>
          <Form.Item name="access_token" label="Access Token">
            <Input></Input>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Card
        ghost="false"
        title="Rich Menu"
        subtitle="This is a rich menu management"
      >
        <Row style={{ margin: 20 }} justify="center">
          <Select
            placeholder="Select a Channel"
            style={{ width: 330 }}
            value={selectToken}
            onChange={(e) => onSelectToken(e)}
          >
            {options.data.length > 0 &&
              options.data.map((ele) => {
                return (
                  <Option key={ele.access_token} value={ele.access_token}>
                    {" "}
                    {ele.channel_name}{" "}
                  </Option>
                );
              })}
          </Select>
          <Button onClick={() => setAddChannel(true)}> ADD CHANNEL </Button>
          <Button
            onClick={() => selectToken !== "" && showDelConfirm()}
            style={{ backgroundColor: "#E74C3C", color: "white" }}
          >
            DELETE CHANNEL
          </Button>
        </Row>
        <div>
          <TableCustom
            config={configTable}
            buttonAdd={configButton}
            setModalAdd={setModalAdd}
            setModalEdit={setModalEdit}
          />
        </div>
      </Card>
    </>
  );
}

export default RichMenu;

import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  Layout,
  Form,
  Input,
  Button,
  Card,
  Row,
  notification,
  Select,
  Tabs,
  Radio,
  Checkbox,
} from "antd";
import itemsRender from "../../utils/itemsRender";
import { HomeOutlined, SafetyCertificateOutlined } from "@ant-design/icons";
import CompanyService from "../../services/company.service";
import TermsService from "../../services/terms.service";
import TextArea from "antd/lib/input/TextArea";
//import EditThemeForm from "./theme";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TableLineOa from "../../components/TableLineOa";
import TableLineOaMyHomeService from "../../components/TableServiceMenuLineOa";
import TableBackEndMenu from "../../components/TableBackendMenu";

const { Content, Header } = Layout;
const { Option } = Select;
function ListCompany() {
  var id = window.location.pathname.substring(
    window.location.pathname.lastIndexOf("/") + 1
  );

  const [fields, setFields] = useState(null);
  const [terms, setTerms] = useState({
    companyId: "",
    terms: null,
    inputValiableTypeText: false,
    inputValiableTypeLink: true,
    termType: "text",
  });
  const [termsLine, setTermsLine] = useState({
    Terms_TH: "",
    Terms_EN: "",
    About_TH: "",
    About_EN: "",
    Privacy_TH: "",
    Privacy_EN: "",
    Consent_TH: "",
    Consent_EN: "",
  });
  const quillRef = useRef(null);

  const openNotificationWithIcon = (type) => {
    if (type === "success") {
      notification[type]({
        message: "Success",
        description: "Change credential successfully.",
      });
    } else {
      notification[type]({
        message: "Error",
        description: "Something when wrong.",
      });
    }
  };
  const onFinish = (values) => {
    CompanyService.editCompany(values)
      .then((data) => {
        openNotificationWithIcon("success");
        //window.history.back();
      })
      .catch((err) => {
        openNotificationWithIcon("error");
        //console.log(err);
      });
  };
  const EditCompanyForm = ({ fields }) => {
    const [form] = Form.useForm();

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 8,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 16,
        },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <Row justify="center" align="middle">
        <Card style={{ width: "100%" }}>
          <Form
            {...formItemLayout}
            form={form}
            name="edit"
            onFinish={onFinish}
            initialValues={fields}
            scrollToFirstError
          >
            <Form.Item
              name="Id"
              label="Id"
              rules={[
                {
                  required: true,
                  message: "Please input the name of company!",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="Name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please input the name of company!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="Name_EN" label="Name_EN">
              <Input />
            </Form.Item>
            <Form.Item name="Description_TH" label="Description_TH">
              <TextArea rows={3} />
            </Form.Item>
            <Form.Item name="Description_EN" label="Description_EN">
              <TextArea rows={3} />
            </Form.Item>
            <Form.Item name="Address_TH" label="Address_TH">
              <Input />
            </Form.Item>
            <Form.Item name="Address_EN" label="Address_EN">
              <Input />
            </Form.Item>
            <Form.Item name="Telephone" label="Telephone">
              <Input />
            </Form.Item>
            <Form.Item name="Fax" label="Fax">
              <Input />
            </Form.Item>
            <Form.Item name="Email" label="Email">
              <Input />
            </Form.Item>
            <Form.Item name="Url" label="Url">
              <Input />
            </Form.Item>
            <Form.Item name="API_Prod" label="API Prod">
              <Input />
            </Form.Item>
            <Form.Item name="API_Qas" label="API Qas">
              <Input />
            </Form.Item>
            <Form.Item name="API_Uat" label="API Uat">
              <Input />
            </Form.Item>
            <Form.Item name="Alias" label="Alias">
              <Input />
            </Form.Item>
            <Form.Item name="MerchantId" label="Merchant Id">
              <Input />
            </Form.Item>
            <Form.Item name="TerminalId" label="Terminal Id">
              <Input />
            </Form.Item>
            <Form.Item name="RedirectUrl_Dev" label="RedirectUrl Dev">
              <Input />
            </Form.Item>
            <Form.Item name="RedirectUrl_Uat" label="RedirectUrl Uat">
              <Input />
            </Form.Item>
            <Form.Item name="RedirectUrl_Prod" label="RedirectUrl Prod">
              <Input />
            </Form.Item>
            <Form.Item name="PublicKey_Dev" label="PublicKey Dev">
              <Input />
            </Form.Item>
            <Form.Item name="PublicKey_Uat" label="PublicKey Uat">
              <Input />
            </Form.Item>
            <Form.Item name="PublicKey_Prod" label="PublicKey Prod">
              <Input />
            </Form.Item>
            <Form.Item name="SecretKey_Dev" label="SecretKey Dev">
              <Input />
            </Form.Item>
            <Form.Item name="SecretKey_Uat" label="SecretKey Uat">
              <Input />
            </Form.Item>
            <Form.Item name="SecretKey_Prod" label="SecretKey Prod">
              <Input />
            </Form.Item>
            <Form.Item name="RemApiHeaders_Qas" label="RemApiHeaders Qas">
              <TextArea rows={5} />
            </Form.Item>
            <Form.Item name="RemApiHeaders_Uat" label="RemApiHeaders Uat">
              <TextArea rows={5} />
            </Form.Item>
            <Form.Item name="RemApiHeaders_Prod" label="RemApiHeaders Prod">
              <TextArea rows={5} />
            </Form.Item>
            <Form.Item name="Line_Message_Api" label="Line Message Api">
              <TextArea rows={5} />
            </Form.Item>
            <Form.Item name="ColorCodePrimary" label="Color Code Primary">
              <Input />
            </Form.Item>
            <Form.Item name="ColorCodeSecond" label="Color Code Second">
              <Input />
            </Form.Item>
            <Form.Item name="ColorCodeText" label="Color Code Text">
              <Input />
            </Form.Item>
            <Form.Item
              name="Status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: "Please input the origins of company!",
                },
              ]}
            >
              <Select>
                <Option value="Active">Active</Option>
                <Option value="Deactive">Deactive</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="ProductType"
              label="ProductType"
              rules={[
                {
                  required: true,
                  message: "Please input the origins of company!",
                },
              ]}
            >
              <Select>
                <Option value="remlite">remlite</Option>
                <Option value="pmlite">pmlite</Option>
                <Option value="rental">rental</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="IsSmartsale"
              label="IsSmartsale"
              rules={[
                {
                  required: true,
                  message: "Please input the Is Smart Sale!",
                },
              ]}
            >
              <Select>
                <Option value={false}>No</Option>
                <Option value={true}>Yes</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="IsSyncUserRem"
              label="IsSyncUserRem"
              rules={[
                {
                  required: true,
                  message: "Please input the Is Sync User Rem!",
                },
              ]}
            >
              <Select>
                <Option value={false}>No</Option>
                <Option value={true}>Yes</Option>
              </Select>
            </Form.Item>
            <Form.Item name="DeveloperCode" label="Developer Code">
              <Input />
            </Form.Item>
            <Form.Item
              name="StorageSize"
              label="Storage Size (GB)"
              rules={[
                {
                  required: true,
                  message: "Please input the Storage Size (GB)!",
                },
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Row>
    );
  };

  const EditTermsForm = ({ fields }) => {
    console.log(fields);
    const [form] = Form.useForm();
    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 8,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 16,
        },
      },
    };
    const tailLayout = {
      wrapperCol: {
        offset: 8,
        span: 8,
      },
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        layout="horizontal"
        size="large"
        form={form}
        name="edit"
        onFinish={onFinishTerms}
        initialValues={{
          ["Value"]: fields.terms ? fields.terms.Value : "",
          ["CompanyId"]: fields.companyId,
          ["Id"]: fields.terms ? fields.terms.Id : "",
        }}
        scrollToFirstError
      >
        <Form.Item name="Type" label="Type">
          <Radio.Group
            onChange={onChangeTerms}
            defaultValue={terms.termType}
            size="large"
          >
            <Radio value={"text"}>Text</Radio>
            <Radio value={"link"}>Link</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          disabled={terms.inputValiableTypeText}
          hidden={terms.inputValiableTypeText}
          name="Value"
          label="Text"
          rules={[
            {
              required: true,
              message: "Please input your Text!",
              // message: "Please input the name of company token!",
            },
          ]}
        >
          <Input.TextArea rows={10} />
        </Form.Item>
        <Form.Item
          disabled={terms.inputValiableTypeLink}
          hidden={terms.inputValiableTypeLink}
          name="Value"
          label="Text"
          rules={[
            {
              required: true,
              message: "Please input your Text!",
              // message: "Please input the name of company token!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="CompanyId" label="Company" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name="Id" label="Id" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const EditCompanyProfileForm = ({ fields }) => {
    //console.log(termsLine);
    //console.log("fields: ", fields);
    const [form] = Form.useForm();
    const tailLayout = {
      wrapperCol: {
        offset: 8,
        span: 8,
      },
    };
    let CompanyId = "",
      Id = "",
      CompanyName_TH = "",
      CompanyName_EN = "",
      Address_TH = "",
      Address_EN = "",
      Tel = "",
      Fax = "",
      Email = "",
      Website = "",
      VideoLink = "",
      ContactEmail = "";
    if (fields) {
      CompanyId = fields.CompanyId;
      Id = fields.Id;
      CompanyName_TH = fields.CompanyName_TH;
      CompanyName_EN = fields.CompanyName_EN;
      Address_TH = fields.Address_TH;
      Address_EN = fields.Address_EN;
      Tel = fields.Tel;
      Fax = fields.Fax;
      Email = fields.Email;
      Website = fields.Website;
      VideoLink = fields.VideoLink;
      ContactEmail = fields.ContactEmail;
    }

    const modules = {
      toolbar: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
        ["clean"],
      ],
    };

    return (
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 12 }}
        layout="horizontal"
        size="large"
        form={form}
        name="edit_profile"
        onFinish={onFinishProfile}
        initialValues={{
          ["CompanyId"]: id,
          ["Id"]: Id,
          ["Terms_TH"]: termsLine.Terms_TH,
          ["Terms_EN"]: termsLine.Terms_EN,
          ["CompanyName_TH"]: CompanyName_TH,
          ["CompanyName_EN"]: CompanyName_EN,
          ["About_TH"]: termsLine.About_TH,
          ["About_EN"]: termsLine.About_EN,
          ["Address_TH"]: Address_TH,
          ["Address_EN"]: Address_EN,
          ["Privacy_TH"]: termsLine.Privacy_TH,
          ["Privacy_EN"]: termsLine.Privacy_EN,
          ["Consent_TH"]: termsLine.Consent_TH,
          ["Consent_EN"]: termsLine.Consent_EN,
          ["Tel"]: Tel,
          ["Fax"]: Fax,
          ["Email"]: Email,
          ["Website"]: Website,
          ["VideoLink"]: VideoLink,
          ["ContactEmail"]: ContactEmail,
        }}
        scrollToFirstError
      >
        <Form.Item name="CompanyName_TH" label="CompanyName_TH">
          <Input />
        </Form.Item>
        <Form.Item name="CompanyName_EN" label="CompanyName_EN">
          <Input />
        </Form.Item>
        <Form.Item name="Address_TH" label="Address_TH">
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="Address_EN" label="Address_EN">
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="About_TH" label="About_TH" style={{ height: "350px" }}>
          <ReactQuill
            onChange={setTermsLine.About_TH}
            style={{ height: "300px" }}
            modules={modules}
          />
        </Form.Item>
        <Form.Item name="About_EN" label="About_EN" style={{ height: "350px" }}>
          <ReactQuill
            onChange={setTermsLine.About_EN}
            style={{ height: "300px" }}
            modules={modules}
            ref={quillRef}
          />
        </Form.Item>
        <Form.Item name="Terms_TH" label="Terms_TH" style={{ height: "350px" }}>
          <ReactQuill
            onChange={setTermsLine.Terms_TH}
            style={{ height: "300px" }}
            modules={modules}
            ref={quillRef}
          />
        </Form.Item>
        <Form.Item name="Terms_EN" label="Terms_EN" style={{ height: "350px" }}>
          <ReactQuill
            onChange={setTermsLine.Terms_EN}
            style={{ height: "300px" }}
            modules={modules}
            ref={quillRef}
          />
        </Form.Item>
        <Form.Item
          name="Privacy_TH"
          label="Privacy_TH"
          style={{ height: "350px" }}
        >
          <ReactQuill
            onChange={setTermsLine.Privacy_EN}
            style={{ height: "300px" }}
            modules={modules}
            ref={quillRef}
          />
        </Form.Item>
        <Form.Item
          name="Privacy_EN"
          label="Privacy_EN"
          style={{ height: "350px" }}
        >
          <ReactQuill
            onChange={setTermsLine.Privacy_EN}
            style={{ height: "300px" }}
            modules={modules}
            ref={quillRef}
          />
        </Form.Item>
        <Form.Item
          name="Consent_TH"
          label="Consent_TH"
          style={{ height: "350px" }}
        >
          <ReactQuill
            onChange={setTermsLine.Consent_TH}
            style={{ height: "300px" }}
            modules={modules}
            ref={quillRef}
          />
        </Form.Item>
        <Form.Item
          name="Consent_EN"
          label="Consent_EN"
          style={{ height: "350px" }}
        >
          <ReactQuill
            onChange={setTermsLine.Consent_EN}
            style={{ height: "300px" }}
            modules={modules}
            ref={quillRef}
          />
        </Form.Item>
        <Form.Item name="Tel" label="Tel">
          <Input />
        </Form.Item>
        <Form.Item name="Fax" label="Fax">
          <Input />
        </Form.Item>
        <Form.Item name="Email" label="Email">
          <Input />
        </Form.Item>
        <Form.Item name="Website" label="Website">
          <Input />
        </Form.Item>
        <Form.Item name="VideoLink" label="VideoLink">
          <Input />
        </Form.Item>
        <Form.Item name="ContactEmail" label="ContactEmail">
          <Input />
        </Form.Item>
        <Form.Item name="CompanyId" label="Company" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name="Id" label="Id" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  };

  const onChangeTerms = (values) => {
    console.log(values);
    if (values.target.value === "text") {
      setTerms({
        companyId: terms.companyId,
        termType: values.target.value,
        inputValiableTypeText: false,
        inputValiableTypeLink: true,
      });
    } else {
      setTerms({
        companyId: terms.companyId,
        termType: values.target.value,
        inputValiableTypeText: true,
        inputValiableTypeLink: false,
      });
    }
  };

  const onFinishTerms = (values) => {
    console.log("Received values of form: ", values);
    values.Type = terms.termType;
    if (values.Id) {
      TermsService.editTerms(values)
        .then((data) => {
          console.log(data);
          openNotificationWithIcon("success", data.data.message);
        })
        .catch((err) => {
          openNotificationWithIcon("error", err.message);
          console.log(err.data);
        });
    } else {
      TermsService.createTerms(values)
        .then((data) => {
          console.log(data);
          openNotificationWithIcon("success", data.data.message);
        })
        .catch((err) => {
          openNotificationWithIcon("error", err.message);
          console.log(err.data);
        });
    }
  };

  const onFinishProfile = (values) => {
    console.log(quillRef.current);
    console.log("Received values of form: ", values);
    if (values.Id) {
      CompanyService.saveCompanyProfile(values)
        .then((data) => {
          console.log(data);
          openNotificationWithIcon("success", data.data.message);
        })
        .catch((err) => {
          openNotificationWithIcon("error", err.message);
          console.log(err.data);
        });
    } else {
      CompanyService.saveCompanyProfile(values)
        .then((data) => {
          console.log(data);
          openNotificationWithIcon("success", data.data.message);
        })
        .catch((err) => {
          openNotificationWithIcon("error", err.message);
          console.log(err.data);
        });
    }
  };

  function loadCompany() {
    CompanyService.getCompanyById(id)
      .then((data) => {
        setFields(data.data);
        setTermsLine({
          Terms_TH: data.data.Terms_TH,
          Terms_EN: data.data.Terms_EN,
          About_TH: data.data.About_TH,
          About_EN: data.data.About_EN,
          Privacy_TH: data.data.Privacy_TH,
          Privacy_EN: data.data.Privacy_EN,
          Consent_TH: data.data.Consent_TH,
          Consent_EN: data.data.Consent_EN,
        });

        // TermsService.getTermsByCompanyId(data.data.Id)
        //   .then((termsdata) => {
        //     if (termsdata.data.Type === "text") {
        //       setTerms({
        //         companyId: data.data.Id,
        //         terms: termsdata.data,
        //         termType: "text",
        //         inputValiableTypeText: false,
        //         inputValiableTypeLink: true,
        //       });
        //     } else {
        //       setTerms({
        //         companyId: data.data.Id,
        //         terms: termsdata.data,
        //         termType: "link",
        //         inputValiableTypeText: true,
        //         inputValiableTypeLink: false,
        //       });
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    loadCompany();
  }, []);

  return (
    <>
      <Breadcrumb
        itemRender={itemsRender}
        items={[
          {
            title: <HomeOutlined />,
            path: "/",
          },
          {
            title: (
              <>
                <SafetyCertificateOutlined /> <span>Company</span>
              </>
            ),
            path: "/company",
          },
          {
            title: "Edit",
          },
        ]}
        style={{ margin: "16px 0" }}
      ></Breadcrumb>
      <Card ghost="false" title="Edit Company" subtitle="This is a company">
        <Tabs
          defaultActiveKey="1"
          size="large"
          type="card"
          items={[
            {
              key: "1",
              label: "Company",
              children: <EditCompanyForm fields={fields} />,
            },
            {
              key: "2",
              label: "Profile",
              children: <EditCompanyProfileForm fields={fields} />,
            },
            {
              key: "4",
              label: "LINE OA",
              children: <TableLineOa id={id} />,
            },
            {
              key: "5",
              label: "LINE OA My Home",
              children: <TableLineOaMyHomeService id={id} />,
            },
            {
              key: "6",
              label: "BackEnd Menu",
              children: <TableBackEndMenu id={id} />,
            },
          ]}
        />
      </Card>
    </>
  );
}

export default ListCompany;

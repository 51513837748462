import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;


class LiffAppService {


    async getLiffAppList(e) {
        let data = await axios({
            url: `${API_URL}/liffapp/listall`,
            method: "GET",
            headers: authHeader(),
        })

        return data
    }



    async createLiffApp(e) {
        let data = await axios({
            url: `${API_URL}/liffapp/create`,
            method: "POST",
            data: {
                liff_app_name: e.liff_app_name,
                liff_url: e.liff_url,
                size: e.size,
                bluetooth: e.bluetooth,
                modular: e.modular
            },
            headers: authHeader(),
        })

        return data
    }


}

export default new LiffAppService();

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Modal, notification } from "antd";
import CredentialsService from "../services/credentials.service";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const { confirm } = Modal;

function TableUnit(prop) {

  const { search , col } = prop

  const columnsCredentials = [
    {
      title: "Name",
      dataIndex: "name",
      key: "Name",
    },
    {
      title: "Origins",
      dataIndex: "origins",
      key: "Origins",
    },
    // {
    //   title: "Server Type",
    //   key: "Type",
    //   dataIndex: "Type",
    // },
    {
      title: "Status",
      key: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Link to={"/credentials/" + text.token_id} style={{ marginRight: 16 }}>
            <EditOutlined /> Edit
          </Link>
          <a onClick={() => showConfirm(text.token_id)}>
            <DeleteOutlined /> Delete
          </a>
        </span>
      ),
    },
  ];
  // state column
  const [columns, setColumns] = useState(columnsCredentials);

  // state table
  const [credentials, setCredentials] = useState([]);
  const [filterCredentials, setFilterCredentials] = useState([])
  // const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);


    ///search
    const filterSearch = () => {

      let temp = [...credentials]
      let result = temp.filter(ele=> ele[`${col}`] !== null ? ele[`${col}`].toLowerCase().includes(search.toLowerCase()): null)
  
      setFilterCredentials([...result])
  
  
    }
  

  // fetch data
  const fetch = async (params = { page: 1, pageSize: 10 }) => {
    try {
      setLoading(true);
      const ret = await CredentialsService.getCredentials();

      // check rem error
      if (ret.status !== 200) {
        throw { message: ret.data.message };
      }

      const data = ret.data;
      setLoading(false);
      setCredentials(data);

      if (data.length > 0) {
        setColumns(columnsCredentials);
      }
    } catch (error) {
      return;
    }
  };

  const showConfirm = (tokenid) => {
    let dataValue = {
      token: tokenid,
    };
    confirm({
      title: "Comfirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to delete credential?",
      onOk() {
        CredentialsService.deleteCredentials(dataValue)
          .then((data) => {
            setLoading(true);
            openNotificationWithIcon("success");
            fetch();
            return new Promise((resolve, reject) => {
              setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            }).catch(() => console.log("Oops errors!"));
          })
          .catch((err) => {
            openNotificationWithIcon("error");
            console.log("Oops errors!");
          });
      },
      onCancel() {},
    });
  };

  const conditionEffect = (e) => {

    if(search !== ""){
      return filterSearch()
    }
    return fetch()

}

  useEffect(() => {

    conditionEffect()

  }, [search]);

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record.token_id}
        dataSource={search !== "" ? filterCredentials : credentials}

        // pagination={pagination}
        loading={loading}
        // onChange={handleTableChange}
        scroll={false}
        className="table-row-pointer"
      />
    </>
  );
}
// ••••••••••••••••••••

const openNotificationWithIcon = (type) => {
  if (type === "success") {
    notification[type]({
      message: "Success",
      description: "Deleted credential successfully.",
    });
  } else {
    notification[type]({
      message: "Error",
      description: "Something when wrong.",
    });
  }
};

export default TableUnit;

import { Link } from "react-router-dom";
const itemRender = (currentRoute, params, items, paths) => {
  const isLast = currentRoute?.path === items[items.length - 1]?.path;

  if (isLast) return <span>{currentRoute.title}</span>

  if (paths.length === 1 && paths[0] === "") return <Link to={"/"}>{currentRoute.title}</Link>

  return <Link to={`${paths.join("/")}`}>{currentRoute.title}</Link>
}

export default itemRender
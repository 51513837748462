import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

class CredentialsService {
  getCredentials() {
    return axios.get(API_URL + "/credentials", {
      headers: authHeader(),
    });
  }

  getCredentialsById(id) {
    return axios.get(API_URL + "/credentials/" + id, {
      headers: authHeader(),
    });
  }

  createCredentials(params) {
    return axios({
      url: `${API_URL}/credentials`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  editCredentials(params) {
    return axios({
      url: `${API_URL}/credentials/edit`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  deleteCredentials(params) {
    return axios({
      url: `${API_URL}/credentials/delete`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  getCompanyById(params) {
    return axios({
      url: `${API_URL}/payment/getcompany`,
      method: "POST",
      data: {
        company_id: params,
      },
    });
  }

  getCompanyByTokenId(params) {
    return axios({
      url: `${API_URL}/payment/getcompany`,
      method: "POST",
      data: {
        company_token: params,
      },
    });
  }
}

export default new CredentialsService();

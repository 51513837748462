import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  Layout,
  Modal,
  Form,
  Input,
  Alert,
  Select,
  Card,
} from "antd";
import TableCustomer from "../../components/TableCustomer";
import {
  HomeOutlined,
  UsergroupAddOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import UserService from "../../services/user.service";
import CompanyService from "../../services/company.service";
import AuthService from "../../services/auth.service";
import { Link } from "react-router-dom";
import { SearchCustom } from "../../components/SearchCustom";
import itemRender from "../../utils/itemsRender";
const { Content, Header } = Layout;
const { Option } = Select;

const CollectionCreateForm = ({
  visible,
  onCreate,
  onCancel,
  onAlert,
  message,
}) => {
  const [form] = Form.useForm();
  const [dataCompany, setDataCompany] = useState([]);
  const getCurrentUser = AuthService.getCurrentUser();
  const getCompany = () => {
    CompanyService.getCompany()
      .then((data) => {
        setDataCompany(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (getCurrentUser.role === 3) {
      getCompany();
    }
  }, []);

  return (
    <Modal
      open={visible}
      title="Create a new user"
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      {onAlert
        ? [<Alert message={message} type="error" showIcon />, <br></br>]
        : ""}
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Please input the email!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please input the passwordn!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="first_name"
          label="First Name"
          rules={[
            {
              required: true,
              message: "Please input the first name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="last_name"
          label="Last Name"
          rules={[
            {
              required: true,
              message: "Please input the last name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {getCurrentUser.role === 3 ? (
          <Form.Item
            name="company"
            label="Company"
            rules={[
              {
                required: true,
                message: "Please select the server type of company token!",
              },
            ]}
          >
            <Select>
              {dataCompany.map((d) => (
                <Option key={d.id}>{d.name}</Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          ""
        )}
        <Form.Item
          name="role"
          label="Role"
          rules={[
            {
              required: true,
              message: "Please select the server type of company token!",
            },
          ]}
        >
          <Select>
            <Select.Option value={1}>Admin</Select.Option>
            <Select.Option value={2}>User</Select.Option>
            {getCurrentUser.role === 3 ? (
              <Select.Option value={3}>Super Admin</Select.Option>
            ) : (
              ""
            )}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

function ListCustomer() {
  const [visible, setVisible] = useState(false);
  const [statusAlert, setStatusAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [search, setSearch] = useState("");
  const [colFilter, setColFilter] = useState("FirstName");

  const onCreate = (values) => {
    console.log("Received values of form: ", values);
    UserService.createUser(values)
      .then((data) => {
        setVisible(false);
        window.location.reload(false);
      })
      .catch((err) => {
        setMessageAlert("Failed! Email is already in use!");
        setStatusAlert(true);
        console.log(err);
      });
  };
  return (
    <>
      <Breadcrumb
        itemRender={itemRender}
        items={[
          {
            title: <HomeOutlined />,
            path: "/",
          },
          {
            title: (
              <>
                <UsergroupAddOutlined /> <span>Customer</span>
              </>
            ),
          },
        ]}
        style={{ margin: "16px 0" }}
      ></Breadcrumb>
      <Card
        ghost="false"
        title="Customer"
        subtitle="Customer for customer portal."
        extra={[
          // <Button key="1" type="primary" onClick={() => [setVisible(true)]}>
          //   <PlusCircleOutlined /> Add New User
          // </Button>,
          <CollectionCreateForm
            key=""
            visible={visible}
            onCreate={onCreate}
            onCancel={() => {
              setVisible(false);
            }}
            onAlert={statusAlert}
            message={messageAlert}
          />,
        ]}
      >
        <SearchCustom
          setSearch={setSearch}
          setColFilter={setColFilter}
          addon={"customer"}
        />
        <br />
        <br />
        <TableCustomer search={search} col={colFilter} />
      </Card>
    </>
  );
}

export default ListCustomer;

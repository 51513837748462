import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Checkbox, Card, Alert } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

import AuthService from "../../services/auth.service";
import * as firebaseui from "firebaseui";
import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBjENaiQZvUbvR3lJmKuPckgSUcYevw2Ys",
  authDomain: "admin-potal-uat.firebaseapp.com",
  projectId: "admin-potal-uat",
  storageBucket: "admin-potal-uat.appspot.com",
  messagingSenderId: "417043007511",
  appId: "1:417043007511:web:b0e0cc88ef7da4bf56f452",
};

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const Login = () => {
  const history = useNavigate();

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [messages, setmessages] = useState("");

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleLogin = () => {
    setmessages("");

    AuthService.login(email, password)
      .then(
        () => {
          //history("/");
          // window.location.href = window.location.href.replace("/login", "");

          window.location.replace(window.location.href.replace("/login", ""));
        },
        (error) => {
          console.log(error);
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.message.toString();

          setmessages(resMessage);
        }
      )
      .catch((err) => {
        setmessages(err.message);
        console.log("Failed:", err);
      });
  };

  // const fbase = firebase.initializeApp(firebaseConfig);
  // const uiConfig = {
  //   callbacks: {
  //     signInSuccess: (currentUser) => {
  //       console.log(currentUser);
  //     },
  //   },
  //   //signInSuccessUrl: "http://localhost:3000", //This URL is used to return to that page when we got success response for phone authentication.
  //   signInOptions: [
  //     {
  //       provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
  //       recaptchaParameters: {
  //         type: "image", // 'audio'
  //         size: "normal", // 'invisible' or 'compact'
  //         badge: "bottomleft", //' bottomright' or 'inline' applies to invisible.
  //       },
  //       defaultCountry: "TH",
  //       defaultNationalNumber: "798888888",
  //       loginHint: "+66798888888",
  //     },
  //     firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  //     firebase.auth.EmailAuthProvider.PROVIDER_ID,
  //   ],
  //   tosUrl: "http://localhost:3000",
  // };
  // var ui = new firebaseui.auth.AuthUI(firebase.auth());
  // ui.start("#firebaseui-auth-container", uiConfig);

  return (
    <Card
      title="Login :"
      style={{
        boxShadow: "0px 0px 29px 0px",
        width: "420px",
      }}
    >
      <Form
        {...layout}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={(values) => handleLogin(values)}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input
            value={email}
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
            onChange={(e) => setemail(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            value={password}
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
            onChange={(e) => setpassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item {...tailLayout} name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
      <div id="firebaseui-auth-container"></div>
      {messages && (
        <div style={{ textAlign: "center" }}>
          <Alert message={messages} type="error" showIcon />
        </div>
      )}
    </Card>
  );
};

export default Login;

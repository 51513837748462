import React, { useState, useEffect } from "react";
import RichMenuService from "../../services/richmenu.service";
import {
  Popconfirm,
  Layout,
  Row,
  Form,
  Input,
  Button,
  Modal,
  Breadcrumb,
  message,
  Radio,
  Checkbox,
  Card,
} from "antd";
import { Link } from "react-router-dom";
import {
  JsonView,
  allExpanded,
  darkStyles,
  defaultStyles,
} from "react-json-view-lite";
import "react-json-view-lite/dist/index.css";

import {
  HomeOutlined,
  LayoutOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import TableCustom from "../../components/TableCustom";
import liffappService from "../../services/liffapp.service";
import itemRender from "../../utils/itemsRender";
const { Content, Header } = Layout;
const { TextArea } = Input;

function LiffApp() {
  const [liffAppList, setLiffAppList] = useState({ status: "", data: [] });
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [form] = Form.useForm();
  const [editInitVal, setEditInitVal] = useState({});
  const [bleBoalean, setBluetooth] = useState(false);
  const [modularBool, setModular] = useState(false);

  // config for TableCustom
  let configTable = {
    columns: [
      {
        title: "LIFF app name",
        dataIndex: "LiffAppName",
        key: "LiffAppName",
      },
      {
        title: "LIFF URL",
        dataIndex: "LiffURL",
        key: "LiffURL",
      },
      {
        title: "Size",
        dataIndex: "Size",
        key: "Size",
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <span>
            <Row>
              <a onClick={() => setInitEdit(true, record)}>
                <EditOutlined /> View
              </a>
              <Popconfirm
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={() => deleteLiffApp(text)}
              >
                <a>
                  <DeleteOutlined /> Delete
                </a>
              </Popconfirm>
            </Row>
          </span>
        ),
      },
    ],
    actions: "",
    data: liffAppList.data,
    addon: "liffapp",
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  /* eslint-disable no-template-curly-in-string */

  // config for TableCustom
  const configButton = {
    visible: true,
    buttonTitle: "Create Liff App",
  };

  const configModal = {
    modalTitle: "Add Liff App",
    body: (
      <div>
        <Form.Item label="Liff App Name" name="LiffAppName">
          <Input />
        </Form.Item>
        <Form.Item label="LIFF URL" name="liffurl">
          <Input />
        </Form.Item>
        <Form.Item label="Size" name="size">
          <Radio.Group>
            <Radio.Button value="compact">Compact</Radio.Button>
            <Radio.Button value="tall">Tall</Radio.Button>
            <Radio.Button value="full">Full</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Optional">
          <Checkbox
            checked={bleBoalean}
            onChange={() => setBluetooth(!bleBoalean)}
          >
            LIFF app supports Bluetooth®
          </Checkbox>
          <Checkbox
            checked={modularBool}
            onChange={() => setModular(!modularBool)}
          >
            LIFF app in modular mode
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Row justify="end">
            <Button htmlType="button" onClick={() => setModalAdd(false)}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Row>
        </Form.Item>
      </div>
    ),
  };

  const configModalEdit = {
    modalTitle: "View RichMenu",
    body: (
      <div>
        <Form.Item name="name" label="Name">
          <Input readOnly />
        </Form.Item>
        <Form.Item name="liffID" label="LIFFID">
          <Input readOnly />
        </Form.Item>

        <JsonView
          name={false}
          data={editInitVal}
          shouldExpandNode={allExpanded}
          style={defaultStyles}
        />

        <Row justify="center">
          <a htmlType="close" onClick={() => cancelEdit(false)}>
            Close
          </a>
        </Row>
      </div>
    ),
  };

  const closeModal = () => {
    form.resetFields(); /// reset fields after modal close
    setModalAdd(false);
  };

  const formFinish = (val) => {
    createRichMenu(val);
    closeModal();
  };

  const requestLiffApp = async () => {
    let data = await liffappService.getLiffAppList();
    setLiffAppList({ status: 200, data: data.data.data });
    return { data: data.data.data };
  };

  const createRichMenu = async (e) => {
    try {
      await liffappService.createLiffApp({
        liff_app_name: e.LiffAppName,
        liff_url: e.liff_url,
        size: e.size,
        bluetooth: bleBoalean == false ? 0 : 1,
        modular: modularBool == false ? 0 : 1,
      });
      message.success("This is a success message");
    } catch (error) {
      console.error(error);
      message.error(`This is an error message : ${error} `, 5);
    }
  };

  const deleteLiffApp = async (e) => {
    try {
      await RichMenuService.deleteLiffApp({ liffID: e });
      await requestLiffApp();
      message.success("This is a success message");
    } catch (error) {
      console.error(error);
      message.error(`This is an error message : ${error} `, 5);
    }
  };

  const setInitEdit = async (e, record) => {
    setEditInitVal(record);
    setModalEdit(e);
  };

  const cancelEdit = (e) => {
    setModalEdit(e);
  };

  const conditionEffect = async () => {};

  useEffect(() => {
    //setDefault values when Edit Modal show up
    form.setFieldsValue(editInitVal);
    requestLiffApp();
    conditionEffect();
  }, [editInitVal]);

  return (
    <>
      <Breadcrumb
        itemRender={itemRender}
        items={[
          {
            title: <HomeOutlined />,
            path: "/",
          },
          {
            title: (
              <>
                <LayoutOutlined /> <span>LiffApp</span>
              </>
            ),
          },
        ]}
        style={{ margin: "16px 0" }}
      ></Breadcrumb>
      <Modal
        title={
          modalAdd === true
            ? configModal.modalTitle
            : configModalEdit.modalTitle
        }
        centered
        open={modalAdd || modalEdit}
        onCancel={() =>
          modalAdd === true ? setModalAdd(false) : cancelEdit(false)
        }
        width={1000}
        footer={null}
      >
        <Form
          form={form}
          {...layout}
          name="nest-messages"
          onFinish={(val) => formFinish(val)}
        >
          {modalAdd === true ? configModal.body : configModalEdit.body}
        </Form>
      </Modal>

      <Card
        ghost="false"
        title="Liff App"
        subtitle="This is a liff app management"
      >
        <Row style={{ margin: 20 }} justify="center"></Row>
        <div>
          <TableCustom
            config={configTable}
            buttonAdd={configButton}
            setModalAdd={setModalAdd}
            setModalEdit={setModalEdit}
          />
        </div>
      </Card>
    </>
  );
}

export default LiffApp;

import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

class UserService {
  getUsers() {
    return axios.get(API_URL + "/user", {
      headers: authHeader(),
    });
  }

  getUserById(id) {
    return axios.get(API_URL + "/user/" + id, {
      headers: authHeader(),
    });
  }

  createUser(params) {
    return axios({
      url: `${API_URL}/user`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  editUser(params) {
    return axios({
      url: `${API_URL}/user/edit`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  deleteUser(params) {
    return axios({
      url: `${API_URL}/user/delete`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  getUserAndCompanyById(id) {
    return axios.get(API_URL + "/user/company/" + id, {
      headers: authHeader(),
    });
  }

  editUserCompany(params) {
    return axios({
      url: `${API_URL}/user/company/edit`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  editUserCompanyBank(params) {
    return axios({
      url: `${API_URL}/user/company/editbank`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }

  editUserCompanyPassword(params) {
    return axios({
      url: `${API_URL}/user/company/editpassword`,
      method: "POST",
      data: params,
      headers: authHeader(),
    });
  }
}

export default new UserService();

// src/LoginButton.tsx
import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "antd";

const loginRequest = {
  scopes: ["openid", "profile", "User.Read", "Calendars.Read"],
};

const SignInButton = () => {
  const { instance, accounts } = useMsal();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleLogin = (loginType) => {
    setAnchorEl(null);

    //if (loginType === "popup") {
    /**
     * When using popup and silent APIs, we recommend setting the redirectUri to a blank page or a page
     * that does not implement MSAL. Keep in mind that all redirect routes must be registered with the application
     * For more information, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
     */
    instance.loginPopup({
      ...loginRequest,
      redirectUri: process.env.REACT_APP_POPUP_REDIRECT_URI, // e.g. /redirect
    });
    // } else if (loginType === "redirect") {
    //   instance.loginRedirect(loginRequest);
    // }
  };

  return (
    <div>
      {accounts.length > 0 ? (
        <div>Welcome, {accounts[0].username}</div>
      ) : (
        <Button onClick={() => handleLogin("popup")} color="inherit">
          Login
        </Button>
      )}
    </div>
  );
};

export default SignInButton;

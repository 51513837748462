import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Input,
  Modal,
  Card,
  Row,
  Col,
  Form,
  DatePicker,
  Breadcrumb,
  Select,
  message,
  Tooltip,
} from "antd";
import { HomeOutlined, SwapOutlined } from "@ant-design/icons";
import itemRender from "../../utils/itemsRender";
import LogsService from "../../services/logs.service";
import moment from "moment";
import _ from "lodash";
import {
  JsonView,
  allExpanded,
  darkStyles,
  defaultStyles,
} from "react-json-view-lite";

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { TextArea } = Input;

const LogAPI = () => {
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [limit, setLimit] = useState(100);
  const [currPage, setCurrPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [columnConfig, setColumnConfig] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (keyword) {
      const filtered = logs.filter((log) => {
        const fieldsToCheck = Object.keys(log);
        return fieldsToCheck.some(
          (field) =>
            typeof log[field] === "string" &&
            log[field].toLowerCase().includes(keyword.toLowerCase())
        );
      });
      setFilteredLogs(filtered);
    } else {
      setFilteredLogs(logs);
    }
  }, [keyword, logs]);

  const fetchLogs = async (params = {}) => {
    setLoading(true);
    try {
      const response = await LogsService.getLogs(params); // Replace with your API URL
      setLogs(response?.data?.data?.data);
      setFilteredLogs(response?.data?.data?.data);
      setTotal(response?.data?.data?.total);
    } catch (error) {
      message.error("Error fetching logs: " + error, 5);
      //console.error("Error fetching logs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAdvancedSearch = (values) => {
    const { dateRange, type } = values;
    const params = {};

    if (dateRange && dateRange.length === 2) {
      params.start_date = moment(dateRange[0].toISOString()).format(
        "YYYY-MM-DD"
      );
      params.end_date = moment(dateRange[1].toISOString()).format("YYYY-MM-DD");
    }

    if (type) {
      params.type = type;
    }

    params.limit = limit;
    params.offset = currPage - 1;

    fetchLogs(params);
  };

  const showModal = (log) => {
    setSelectedLog(log);
    setModalVisible(true);
  };

  const handleOk = () => {
    setModalVisible(false);
    setSelectedLog(null);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setSelectedLog(null);
  };

  const getDynamicColumns = () => {
    if (filteredLogs.length === 0) {
      return [];
    }

    if (filteredLogs.length === 0) {
      return [];
    }

    const dynamicColumns = Object.keys(filteredLogs[0]).map((key, index) => {
      let sorter = null;
      if (key === "id") {
        sorter = (a, b) => a[key] - b[key];
      } else if (typeof filteredLogs[0][key] === "string") {
        sorter = (a, b) => a[key].localeCompare(b[key]);
      } else if (typeof filteredLogs[0][key] === "number") {
        sorter = (a, b) => a[key] - b[key];
      }

      return {
        title: key.charAt(0).toUpperCase() + key.slice(1),
        dataIndex: key,
        key: index,
        responsive: ["md"],
        ellipsis: {
          showTitle: false,
        },
        render: (value) => {
          if (value && value.length > 20) {
            return (
              <Tooltip placement="topLeft" title={value}>
                {value}
              </Tooltip>
            );
          } else {
            return value;
          }
        },
        sorter,
      };
    });

    return dynamicColumns.concat({
      title: "Action",
      key: "action",
      fixed: "right",
      render: (text, record) => (
        <Button type={"link"} onClick={() => showModal(record)}>
          Details
        </Button>
      ),
    });
  };

  const columns = getDynamicColumns();

  function isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  return (
    <div>
      <Breadcrumb
        itemRender={itemRender}
        items={[
          {
            title: <HomeOutlined />,
            path: "/",
          },
          {
            title: (
              <>
                <SwapOutlined /> <span>Log API</span>
              </>
            ),
            path: "/",
          },
        ]}
        style={{ margin: "16px 0" }}
      ></Breadcrumb>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Search Logs">
            <Form layout="vertical" onFinish={handleAdvancedSearch}>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Form.Item name="keyword" label="Keyword">
                    <Search
                      placeholder="Search logs"
                      style={{ marginBottom: 16 }}
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="dateRange"
                    label="Date Range"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Date Range",
                      },
                    ]}
                  >
                    <RangePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="type"
                    label="Type Logs"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Type Logs",
                      },
                    ]}
                  >
                    <Select placeholder="Select a type">
                      <Option value="payment_log">Log Payment</Option>
                      <Option value="payment">Transaction Payment</Option>
                      <Option value="chat_log_receive">
                        Transaction Chat Log Receive
                      </Option>
                      <Option value="chat_log_send">
                        Transaction Chat Log Send
                      </Option>
                      <Option value="email">Transaction Email</Option>
                      <Option value="email_otp">Transaction Email OTP</Option>
                      <Option value="feedback">Transaction Feedback</Option>
                      <Option value="notification_line">
                        Transaction Notification Line
                      </Option>
                      <Option value="sms_otp">Transaction SMS OTP</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginTop: "30px", width: "50%" }}
                    >
                      Search
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={24}>
          <Card title="Log Entries">
            <Table
              scroll={{ x: "100%" }}
              columns={columns}
              dataSource={filteredLogs}
              rowKey="id"
              loading={loading}
              pagination={{
                size: "default",
                total: total ? total : 0,
                current: currPage,
                showLessItems: true,
                pageSize: limit,
                showTotal: (total, range) =>
                  `${range[0]} - ${range[1]} of ${total}`,
                onChange: (page, pageSize) => {
                  setCurrPage(page);
                  setLimit(pageSize);
                },
                showSizeChanger: true,
              }}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="Log Details"
        open={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="70%"
      >
        {selectedLog && (
          <div>
            {Object.keys(selectedLog).map((key) => {
              let numRows = Math.ceil(selectedLog[key]?.length / 60);
              //console.log(_.startCase(key), selectedLog[key]);
              return (
                <div key={key} style={{ marginBottom: "16px" }}>
                  <strong>{_.startCase(key)} : </strong>
                  {numRows > 3 && isJson(selectedLog[key]) ? (
                    <JsonView
                      name={false}
                      data={JSON.parse(selectedLog[key])}
                      shouldExpandNode={allExpanded}
                      style={defaultStyles}
                    />
                  ) : (
                    <TextArea
                      rows={numRows.toString() === "NaN" ? 1 : numRows}
                      value={selectedLog[key]}
                      readOnly
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default LogAPI;
